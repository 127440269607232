import { useMemo } from 'react';

import googleIntegration from '@ergeon/core-components/exports/googleIntegration';

import { checkRouteList } from '../utils/utils';
import customScripts from '../utils/custom-scripts';

const NO_BUTTON_PAGES = ['/fences', '/request-quote', '/artificial-grass'];

export const useLocationCheck = (location: Location) => {
  const isDraftEditorTemplate = useMemo(() => {
    const draftEditorTemplates = ['/draft-editor'];
    return checkRouteList(draftEditorTemplates, location);
  }, [location]);

  const isNarrowTemplate = useMemo(() => {
    const narrowTemplates = ['/request-quote'];
    return checkRouteList(narrowTemplates, location);
  }, [location]);

  const isNoFooterTemplate = useMemo(() => {
    const noFooterTemplates = ['/request-quote'];
    return checkRouteList(noFooterTemplates, location);
  }, [location]);

  const isShowGetAQuoteButton = useMemo(() => {
    return !checkRouteList(NO_BUTTON_PAGES, location);
  }, [location]);

  const isShowPricingButton = useMemo(() => {
    return !checkRouteList(['/artificial-grass', '/app/estimates'], location);
  }, [location]);

  const isShowMenuButton = useMemo(() => {
    return !checkRouteList(['/app/estimates'], location);
  }, [location]);

  const isCustomerApp = useMemo(() => {
    return checkRouteList(['/app'], location);
  }, [location]);

  const isUTM = useMemo(() => {
    return checkRouteList(['/utm'], location);
  }, [location]);

  const isProPage = useMemo(() => {
    return checkRouteList(['/pro'], location);
  }, [location]);

  const isHomePage = useMemo(() => {
    return checkRouteList(['/'], location);
  }, [location]);

  const isShowCTAButton = useMemo(() => {
    return !checkRouteList(NO_BUTTON_PAGES, location);
  }, [location]);

  return {
    isDraftEditorTemplate,
    isNarrowTemplate,
    isNoFooterTemplate,
    isShowGetAQuoteButton,
    isShowPricingButton,
    isShowMenuButton,
    isCustomerApp,
    isUTM,
    isProPage,
    isShowCTAButton,
    isHomePage,
  };
};

export const useInitGoogleLoader = () => {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const { DRAW_MAP_GOOGLE_LIBRARIES } = require('@ergeon/draw-map');
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const { ERG_MAP_COMPONENT_LIBRARIES } = require('@ergeon/map-component');
  const { initGoogleLoader, ADDRESS_INPUT_LIBRARIES } = googleIntegration;
  initGoogleLoader(
    process.env.GOOGLE_MAPS_API_KEY as string,
    ADDRESS_INPUT_LIBRARIES,
    ERG_MAP_COMPONENT_LIBRARIES,
    DRAW_MAP_GOOGLE_LIBRARIES,
  );
};

export const useMainInit = () => {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const { initUTMs } = require('@ergeon/erg-utms');
  initUTMs('utm-iframe', process.env.WEBSITE_DOMAIN, [`${process.env.FENCEQUOTING_HOST}/utm/`]);
  customScripts();
};
