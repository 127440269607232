import React from 'react';

import initI18n from '../i18n/initI18n';

import { useLocationCheck } from './customHooks';
import { City } from './types';
import MainLayout from './MainLayout';

export interface LayoutProps {
  children: JSX.Element;
  location: Location;
  data?: {
    allSanityCityData?: {
      nodes?: City[];
    };
  };
  phoneNumber: string;
}

const Layout = (props: LayoutProps) => {
  const { location, children } = props;

  const {
    isDraftEditorTemplate,
    isUTM,
    isProPage,
  } = useLocationCheck(location);

  if (isProPage) {
    initI18n();
  }

  if (isUTM || isDraftEditorTemplate) {
    return children;
  }

  return (
    <MainLayout {...props}>
      {children}
    </MainLayout>
  );
};

export default Layout;
