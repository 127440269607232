import React from 'react';

import { Link } from 'gatsby';

import { ReactComponent as ArrowBottom } from '../../../assets/icon-arrow-bottom.svg';
import { LINKS } from '../constants';

import MenuDesktop from './MenuDesktop';

const DropdownDesktop = () => {
  return (
    <div className="flex flex-row items-center justify-start gap-[0px_6px]">
      <div className="group/dropdown relative inline-block">
        <button className="rounded-roundings-xs bg-transparent group-hover/dropdown:bg-gray-5 h-9 flex flex-row items-center justify-center py-2 px-4 box-border gap-[0px_6px] font-button-medium text-sm text-black-1 cursor-pointer">
          <div className="relative leading-[20px] font-semibold">Fences</div>
          <div className="w-[17.52px] h-[17.52px] flex flex-row items-center justify-center">
            <ArrowBottom className="w-3 relative h-3 group-hover/dropdown:rotate-180 transition-transform" />
          </div>
        </button>
        <div className="absolute opacity-0 scale-95 invisible group-hover/dropdown:visible group-hover/dropdown:opacity-100 group-hover/dropdown:scale-100 pt-[20px] left-[-124px] transform transition-all duration-300 ease-out origin-top">
          <MenuDesktop />
        </div>
      </div>
      {LINKS.map((link, index) => (
        <Link key={index} to={link.path} className="rounded-roundings-xs h-9 flex flex-row items-center justify-center py-2 px-4 box-border hover:bg-gray-5 text-black-1 hover:text-black-1">
          <div className="relative leading-[20px] font-semibold">{link.label}</div>
        </Link>
      ))}
    </div>
  );
};

export default DropdownDesktop;
