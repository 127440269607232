import * as tawkModule from './tawk';
import VisitorID from './utils/VisitorID';
import ReferralCode from './utils/ReferralCode';

export {
  syncUTMs as initUTMs,
  initUTMs as utmsReady,
  processIncomingUTMs,
  queryAndSaveIPInfo,
} from './init';

export {
  getBaseEventData,
  getCurrentData,
  getInitialData,
  getSessionData,
  getIpInfoFromStorage,
  
} from './utils/UTMData';

export {
  VISITOR_ID_KEY,
  PARAMS_TIME_STAMP_KEY,
} from './constants';

export const getVisitorId = VisitorID.get;

export const getReferralCode = ReferralCode.get;

export const tawk = tawkModule;
