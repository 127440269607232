import isEqual from 'lodash/isEqual';
import once from 'lodash/once';

import {version as packageVersion} from '../package.json';
import {localStorage, sessionStorage} from './utils/Storage';
import {getCurrentData, getSharedData} from './utils/UTMData';
import {isDataExpired, isHeadlessBrowser, isReferralExpired} from './utils/utils';
import DomainData from './utils/DomainData';
import URLHelper from './utils/URLHelper';
import VisitorID from './utils/VisitorID';
import ReferralCode from './utils/ReferralCode';
import {
  SESSION_INITIAL_KEY, RESTORE_SESSION_PERIOD, VISITOR_ID_KEY, NO_REFERRER,
  REFERRAL_KEY, REFERRAL_CODE_KEY, PARAMS_TIME_STAMP_KEY, IP_LOOKUP_URL,
  IP_LOOKUP_URL_STAGING,
} from './constants';
import IPAddressLookup from './utils/IPAddressLookup';

interface IPConfigOptionsInterface {
  env: 'production' | 'development' | 'staging';
}

const _log = (...args: any) => {
  if (process.env.NODE_ENV !== 'test') return;
  window.console.log(...args);
};

/**
 * Initialize UTMs and share with all target domains
 */
export async function syncUTMs(containerID: string, serviceName: string, targetURLs: string[]) {
  if (isHeadlessBrowser()) {
    // Do nothing in headless Chrome
    return;
  }
  await initUTMs();
  const data = await getSharedData();
  for (const targetURL of targetURLs) {
    await DomainData.sendTo({containerID, senderName: serviceName, targetURL, data});
  }
}

/**
 * This function initializes UTMs by checking data set by another domains’ data and storages,
 * full algorithm schema is available by bit.ly/3eGB10g link
 *
 * NOTE: it could be called many times, we restrict invocation to only one
 */
export const initUTMs = once(async function() {
  if (isHeadlessBrowser()) {
    // Do nothing in headless Chrome
    return;
  }
  const currentData = await getCurrentData();
  const baseKeys = Object.keys(currentData);
  const fullSessionData = await sessionStorage.getData([...baseKeys, SESSION_INITIAL_KEY]);
  const fullInitialData = await localStorage.getData([...baseKeys, VISITOR_ID_KEY]);
  const currentTs = currentData[PARAMS_TIME_STAMP_KEY];
  // Session data exists? (could be set by another domain)
  if (fullSessionData) {
    const {[SESSION_INITIAL_KEY]: initialDataFromSession} = fullSessionData;
    // Get visitor ID and timestamp from initial data and session initial data
    const {
      [VISITOR_ID_KEY]: sessionVisitorId,
      [PARAMS_TIME_STAMP_KEY]: sessionVisitorTs,
    } = initialDataFromSession ?? {};
    const {
      [VISITOR_ID_KEY]: initialVisitorId,
      [PARAMS_TIME_STAMP_KEY]: initialVisitorTs,
    } = fullInitialData ?? {};
    // Check if initial data is set
    if (fullInitialData && !sessionVisitorId) {
      // Unusual situation, nothing we can do
      window.console.warn(`Unexpected data from session received: ${VISITOR_ID_KEY} is empty`);
      return;
    }
    if (!fullInitialData || fullInitialData && initialVisitorId !== sessionVisitorId) {
      // Save initial data if it’s empty or visitor ID changed
      if (initialVisitorId !== sessionVisitorId) {
        window.console.warn(
          "VisitorID mismatch, overwriting with session",
          `Initial: ${initialVisitorId}, created at ${initialVisitorTs}`,
          `Session: ${sessionVisitorId}, created at ${sessionVisitorTs}`,
        );
      }
      await localStorage.setData(initialDataFromSession);
    }
    if (isReferralExpired(fullInitialData)) {
      initialDataFromSession[REFERRAL_KEY] = ReferralCode.getReferralFromUrl();
      await localStorage.setData(initialDataFromSession);
    }
    _log('Nothing to be set');
    return;
  }
  // No session data (first domain visited)
  const sessionData = {...currentData};
  if (fullInitialData && !isDataExpired(fullInitialData)) {
    const {
      [REFERRAL_KEY]: referralInitialData,
    } = fullInitialData ?? {};
    const {
      [REFERRAL_KEY]: referralCurrentData,
    } = currentData || {};
    // Set initial data into the session
    const initialLandingPage = fullInitialData['document']?.['href'];
    const currentReferrer: string = currentData['document']['referrer'];
    const initialTs = fullInitialData[PARAMS_TIME_STAMP_KEY];
    if (
      initialLandingPage && currentReferrer && currentReferrer !== NO_REFERRER &&
        currentTs - initialTs < RESTORE_SESSION_PERIOD &&
        URLHelper.getHost(initialLandingPage) === URLHelper.getHost(currentReferrer)
    ) {
      // On some browsers if page is restored - session may be lost, but referrer is kept.
      // We can restore the session from initial data if referrer match with initial landing page.
      sessionData['utm'] = fullInitialData['utm'];
      sessionData['document'] = fullInitialData['document'];
    }
    // Check if referral code exists on page restore, update local storage with new value.
    const currentReferral = referralCurrentData?.[REFERRAL_CODE_KEY];
    const initialReferral = referralInitialData?.[REFERRAL_CODE_KEY];
    if (
      (currentReferral && !isEqual(currentReferral, initialReferral)) ||
      isReferralExpired(fullInitialData) ||
      !referralInitialData
    ) {
      fullInitialData[REFERRAL_KEY] = currentData[REFERRAL_KEY];
      await localStorage.setData(fullInitialData);
    }
    sessionData[SESSION_INITIAL_KEY] = fullInitialData;
  } else {
    // No data or it’s expired, update local storage data
    sessionData[SESSION_INITIAL_KEY] = {...currentData, [VISITOR_ID_KEY]: VisitorID.generate()};
    await localStorage.setData(sessionData[SESSION_INITIAL_KEY]);
  }
  await sessionStorage.setData(sessionData);
  return;
});

export async function queryAndSaveIPInfo(options?: IPConfigOptionsInterface) {
  const ipUrl = options?.env === 'production' ? IP_LOOKUP_URL : IP_LOOKUP_URL_STAGING;
  try {
    const user_ip_info = await IPAddressLookup(ipUrl);

    // get ip and overwrite it everywhere.
    await localStorage.setData({ user_ip_info });
    await sessionStorage.setData({ user_ip_info });
  } catch (e) {
    window.console.warn('IP lookup failed', e.message);
  }
  return;
}

export function processIncomingUTMs(receiverName: string) {
  return DomainData.receiveFrom(async receivedData => {
    const {senderName, data: {initial: initialData, session: sessionData}} = receivedData;
    if (packageVersion !== initialData.packageVersion) {
      window.console.warn(
        "erg-utms package version mismatch. ",
        `${receiverName}: ${packageVersion}`,
        `${senderName}: ${initialData.packageVersion}`,
      );
    }
    const visitorId = await localStorage.storage.getItem(VISITOR_ID_KEY);
    if (visitorId && initialData[VISITOR_ID_KEY] === visitorId) {
      // Do nothing
    } else {
      if (visitorId && initialData[VISITOR_ID_KEY] !== visitorId) {
        window.console.warn(
          `${receiverName} received visitorId=${initialData[VISITOR_ID_KEY]} from ${senderName}, `,
          `but already have ${visitorId}, overwriting.`,
        );
      }
      // Either overwrite existing or set up new
      await localStorage.storage.setItems(initialData);
    }
    await sessionStorage.storage.setItems(sessionData);
    return;
  });
}
