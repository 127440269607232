import { getVisitorId } from '@ergeon/erg-utms';

import { trackError } from './trackError';

export const track = (eventName: string, data: object) => {

  // BEGIN: Google Tag manager
  // @ts-ignore
  const dataLayer = window.dataLayer || [];

  if (!dataLayer) {
    trackError('No GTM');
  }
  getVisitorId()
    .then((visitorId) => {
      dataLayer.push({
        event: eventName,
        data: {
          ...data,
          uuid: visitorId,
          anonymousId: visitorId,
        },
      });
    })
    .catch((e) => {
      trackError(e);
    });
  // END: Google Tag manager

  if (process.env.NODE_ENV !== 'production') {
    console.info(
      `%cEvent %c ${eventName}`, // eslint-disable-line
      'color: #FF8118; font-size:24px;',
      'color: #00B9F3; font-size:24px;',
      data,
    );
  }
};
