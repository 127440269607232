import { navigate } from 'gatsby';
import * as utils from '@ergeon/core-components/exports/utils';
import { isUpcomingFeaturesEnabled } from '@ergeon/erg-utils-js';
import sortBy from 'lodash/sortBy';
import compact from 'lodash/compact';
import get from 'lodash/get';
import { AxiosError } from 'axios';
import mapValues from 'lodash/mapValues';
import castArray from 'lodash/castArray';
import queryString from 'query-string';

import { Lead } from '../components/types';
import { City } from '../components/AppCityPage/types';

import { CITIES_PAGE_PATH, DEFAULT_ZIP } from './constants';

type RetryParameters = (noOfRequest: number, callback: (error?: unknown) => unknown) => Promise<never | unknown>;
type ResponseError = AxiosError & { responseText: string; statusText: string };
type OrderType = { schema: string[], code: string[], catalog_type: string };

const CATALOG_TYPE_FENCE = 'fence-side';

export const isChristmasTime = () => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return utils.isChristmasTime(new Date());
};

export const showUpcomingFeatures = (issueNumber: string) => {
  if (process.env.SHOW_UPCOMING_FEATURES === 'true') {
    return true;
  }
  return isUpcomingFeaturesEnabled(issueNumber);
};

export const getParameterByName = (name: string, url?: string) => {
  if (!url) url = window.location.href;
  name = name.replace(/[[\]]/g, '\\$&');
  const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`),
    results = regex.exec(url);
  if (!results) return;
  if (!results[2]) return '';
  let parameterValue;
  try {
    parameterValue = decodeURIComponent(results[2].replace(/\+/g, ' '));
  } catch (error) {
    parameterValue = '';
    console.error(error);
  }
  return parameterValue;
};

export const isPDFMode = () => {
  const asPDF = getParameterByName('asPDF', '') || '';

  return asPDF.toLocaleLowerCase() === 'true';
};

export const scrollTop = () => {
  document.body.scrollTop = 0; // For Safari
  document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
};

export const checkRouteList = (routes: string[] = [], location: Location) => {
  const pathname = location ? location.pathname : '/';
  let included = false;
  routes.forEach((path) => {
    if (pathname === path || pathname.startsWith(`${path}/`)) included = true;
  });
  return included;
};

export const getLocationList = (cities: { city: string, slug: string }[]) => {
  try {
    return sortBy(cities, 'city').map((city) => ({
      text: city.city,
      url: `${CITIES_PAGE_PATH}/${city.slug}/`,
    }));
  } catch (e) {
    console.error(e);
    return [];
  }
};

export const updateLeadWithZipcode = (lead: Lead): Lead => {
  const zipcode = get(lead, `productAvailability.products[${lead.product_slug}]`) ?
    lead.address?.zipcode :
    DEFAULT_ZIP;

  return {
    ...lead,
    zipcode,
  };
};

export const makePhoneLink = (phone: string, options = { countryCode: '+1' }) => {
  let link = phone.replace(/[^\d+]/g, '');
  if (!(/^\+/).test(link)) {
    link = `${options.countryCode}${link}`;
  }
  return `tel:${link}`;
};

export const formatFooterLicenses = (licenses?: City['licenses'], licenseUrl?: string) => {
  if (!licenses) return null;
  return compact(licenses).map((license) => {
    return {
      name: `License #: ${license}`,
      url: licenseUrl,
    };
  });
};

export const isEmpty = (value: string | null | undefined) => value === undefined || value === null || value === '';

export const retryRequest: RetryParameters = async(noOfRequest = 0, callback) => {
  try {
    return await callback();
  } catch (error) {
    if (noOfRequest === 0) return callback(error);
    return retryRequest(noOfRequest - 1, callback);
  }
};

export const parseError = (error: unknown) => {
  try {
    const json = JSON.parse((error as ResponseError).responseText);

    for (const key in json) {
      const value = json[key];
      return `${Array.isArray(value) ? value[0] : value}: '${key}'`;
    }
  } catch (e) {
    return (error as ResponseError).statusText;
  }
};

export const getAdvancedEditorUrl = (order: OrderType, zipcode?: string) => {
  const sep = ',';
  let query = '';
  if (order['catalog_type'] == CATALOG_TYPE_FENCE) {
    query = '/fence3d?schema=';
  } else {
    query = '/gate3d?schema=';
  }
  order.schema.forEach((key) => {
    query += key + sep;
  });
  query = query.slice(0, -1);
  query += '&code=';
  order.code.forEach((key) => {
    query += key + sep;
  });
  query = query.slice(0, -1);
  query += '&options=true&mode=3d';
  if (zipcode) {
    query += `&zipcode=${zipcode}`;
  }
  return query;
};

export const customNavigate = (to: { search: string; pathname: string }) => {
  const { pathname, search } = to;
  if (search) {
    navigate(`${pathname}${search}`);
    return;
  }
  navigate(`${pathname}`);
};

export const getParams = (search = '') => {
  return mapValues(queryString.parse(search), (value) => castArray(value)[0]);
};

export const getProfileName = (fullName = '') => {
  return fullName.split(' ')
    .map((name, i) => (i === 0 ? name : `${name[0]}.`))
    .join(' ');
};

export const formatDate = (dateString: string) => new Date(dateString).toLocaleDateString('en-US', {
  month: 'short', // abbreviated month name
  day: '2-digit', // two-digit day
  year: 'numeric', // four-digit year
});