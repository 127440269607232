import React, { useMemo } from 'react';

import TopPanel, {
  TopPanelLogo,
} from '@ergeon/core-components/exports/UniversalTopPanel';
import { ReactComponent as ErgeonLogo } from '@ergeon/core-components/src/assets/ergeon-logo-blue.svg';

import TopPanelBase from './TopPanelBase';
import TopPanelProPage from './TopPanelProPage';

const PHONE_NUMBER = '+18883743661';

import './UniversalTopPanelRender.scss';

interface TopPanelRender {
  ergeonUrl?: string;
  fenceQuotingUrl?: string;
  isProPage?: boolean;
  languageToggle?: JSX.Element;
  UTMquery?: string;
  phoneNumber?: string;
  showChristmasHat?: boolean;
  onCTAClick?: () => void;
  isHomePage: boolean;
}

const UniversalTopPanelRender = (props: TopPanelRender) => {
  const {
    ergeonUrl = process.env.HOME_PAGE,
    fenceQuotingUrl = process.env.FENCEQUOTING_HOST,
    isProPage,
    languageToggle,
    UTMquery = '',
    phoneNumber = PHONE_NUMBER,
    showChristmasHat,
    onCTAClick,
  } = props;

  const renderNavItems = useMemo(() => {
    if (isProPage) {
      return (
        <TopPanelProPage
          languageToggle={languageToggle}
          phoneLink={phoneNumber}
          onCTAClick={onCTAClick}
        />
      );
    }
    return (
      <TopPanelBase
        address={`${fenceQuotingUrl}${UTMquery}`}
        onCTAClick={onCTAClick}
        phoneLink={phoneNumber}
      />
    );
  }, [onCTAClick, phoneNumber, fenceQuotingUrl, UTMquery]);

  return (
    <TopPanel
      data-testid="top-panel"
    >
      <TopPanelLogo
        data-testid="top-panel-logo"
        href={ergeonUrl}
        showChristmasHat={showChristmasHat}
        showPROLabel={isProPage}
      >
        <ErgeonLogo className="top-panel__logo" />
      </TopPanelLogo>
      {renderNavItems}
    </TopPanel>
  );
};

export default UniversalTopPanelRender;
