import * as queryString from 'query-string';

import {REFERRAL_TIMESTAMP_KEY, REFERRAL_KEY, REFERRAL_CODE_KEY} from '../constants';
import {getInitialData} from './UTMData';
import TimeStamp from './TimeStamp';

const ReferralCode = {
    async get() {
      const initialData = await getInitialData();
      const referralObject = initialData[REFERRAL_KEY];
      return referralObject ? referralObject[REFERRAL_CODE_KEY] : '';
    },
    getReferralFromUrl() {
        const parsedQuery = queryString.parse(window.location.search);
        const referralCode = parsedQuery['referral_code'] as string ?? '';
        return {
            referralCode,
            [REFERRAL_TIMESTAMP_KEY]: referralCode ? TimeStamp.get() : 0,
        };
    },
};

export default ReferralCode;
