import localForage from 'localforage';
import memoryStorageDriver from 'localforage-memoryStorageDriver';
import * as sessionStorageWrapper from 'localforage-sessionstoragewrapper';
import {extendPrototype as extendGetPrototype} from 'localforage-getitems';
import {extendPrototype as extendSetPrototype} from 'localforage-setitems';

import {version as packageVersion} from '../../package.json';
import {PARAMS_TIME_STAMP_KEY} from '../constants';
import {UTMs} from './UTMParser';

// Prepare an enhanced version of the LocalForage
extendGetPrototype(localForage);
extendSetPrototype(localForage);

type StorageType = 'localStorage' | 'sessionStorage';

// Every storage is defined by these 3 values
type StorageConfig = {
  name: string,
  storageType: StorageType,
  driver?: string,
};

// These interfaces show how storage is defined and used
interface StorageDefinition {
  storage: LocalForage;
  createStorage(config: StorageConfig): LocalForage;
}
interface StorageManipulation {
  getData(keys: string[]): Promise<LocalForageGetItemsResult>;
  setData(data: UTMs): Promise<void>;
}

abstract class BaseStorage implements StorageDefinition, StorageManipulation {
  storage: LocalForage;

  /**
   * Creates the storage, uses synthetic memory storage as a fallback
   * @param config
   */
  constructor(config: StorageConfig) {
    if (this.isStorageAvailable(config) && process.env.NODE_ENV !== 'test') {
      this.storage = this.createStorage(config);
    } else {
      this.storage = this.createMemoryStorage(config);
      window.console.log(
        `Fallback to in-memory storage, ${config.storageType} is not available`,
      );
    }
  }

  /**
   * Try saving storage item to understand if it works
   * @param config
   * @returns
   */
   private isStorageAvailable(config: StorageConfig): boolean {
    try {
      const storage = window[config.storageType];
      const testRecord = '__storage_test__';
      storage.setItem(testRecord, testRecord);
      storage.removeItem(testRecord);
      return true;
    } catch (e) {
      return false;
    }
  }

  private createMemoryStorage(config: StorageConfig): LocalForage {
    const storage = localForage.createInstance({name: config.name});
    storage.defineDriver(memoryStorageDriver).then(
      () => storage.setDriver(memoryStorageDriver._driver),
    );
    return storage;
  }

  createStorage(config: StorageConfig): LocalForage {
    return localForage.createInstance(config);
  }

  async getData(keys: string[]) {
    const ts = await this.storage.getItem(PARAMS_TIME_STAMP_KEY);
    if (!ts) return null;
    return this.storage.getItems(keys);
  }

  async setData(data: UTMs) {
    return this.storage.setItems({...data, packageVersion});
  }
}

class SessionStorage extends BaseStorage {
  constructor(name: string) {
    const config: StorageConfig = {
      name,
      storageType: 'sessionStorage',
    };
    super(config);
  }

  createStorage(config: StorageConfig) {
    const storage = super.createStorage(config);
    storage.defineDriver(sessionStorageWrapper).then(
      () => storage.setDriver(sessionStorageWrapper._driver),
    );
    return storage;
  }
}

class LocalStorage extends BaseStorage {
  constructor(name: string) {
    const config: StorageConfig = {
      name,
      storageType: 'localStorage',
      driver: localForage.LOCALSTORAGE
    };
    super(config);
  }
}

// Export as singletons
export const sessionStorage = new SessionStorage('erg-utms-s-v1');
export const localStorage = new LocalStorage('erg-utms-ls-v1');
