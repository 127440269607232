import { CITIES_PAGE_PATH } from '../../../utils/constants';

import { CitiesJSONData } from './types';

export enum SupportedState {
  California = 'CA',
  DistrictOfColumbia = 'DC',
  Texas = 'TX',
  Georgia = 'GA',
  Maryland = 'MD',
  Pennsylvania = 'PA',
  Virginia = 'VA',
  Florida = 'FL',
  Washington = 'WA',
  Illinois = 'IL',
  NewJersey = 'NJ',
}

enum Redirect {
  California = 'San Jose',
  DistrictOfColumbia = 'Washington',
  Texas = 'Dallas',
  Georgia = 'Atlanta',
  Maryland = 'Baltimore',
  Pennsylvania = 'Pittsburgh',
  Virginia = 'Arlington',
  Florida = 'Miami',
  Washington = 'Seattle',
  Illinois = 'Naperville',
  NewJersey = 'Marlton',
}

// we also need to match the correct State otherwise we might get wrong results
export const getExistingCitySlug = (
  city: string | undefined,
  state: SupportedState,
  citiesData: CitiesJSONData | null,
) => {
  const cityPage = citiesData?.cities?.find((c) => c.city === city && c.state === state);
  if (cityPage) {
    return cityPage.slug.current;
  }
  const neighboringCityPage = citiesData?.neighboring.find((c) => c.city === city && c.state === state);
  if (neighboringCityPage) {
    return neighboringCityPage?.to_slug;
  }

  // in case the city doesn't have a page we redirect to the default state one
  for (const obj in SupportedState) {
    // @ts-expect-error
    if (SupportedState[obj as keyof SupportedState] == state) {
      // @ts-expect-error
      return citiesData?.cities.find((c) => c.city === Redirect[obj])?.slug.current;
    }
  }

  throw new Error(`Cannot find city for state: "${state}"`);
};

export const getCityPath = (citySlug: string) => `${CITIES_PAGE_PATH}/${citySlug}`;
