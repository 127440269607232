import { CLICK_TO_CALL } from '../events';

import { track } from './track';

export const listenClickToCall = () => {
  const query = `[data-tracking-id="${CLICK_TO_CALL}"]`;
  const allClickToCallElements = document.querySelectorAll(query);

  allClickToCallElements.forEach(function(element) {
    element.addEventListener('click', function(event) {
      const target = event.target  as HTMLAnchorElement;
      if (!target) return;

      const innerText = target.innerText;
      const phoneNumber = target.getAttribute('href') || target.parentElement && target.parentElement.getAttribute('href');

      track(CLICK_TO_CALL, {
        type: 'link',
        inner_text: innerText,
        link_href: phoneNumber,
      });
    });
  });
};