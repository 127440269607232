import React from 'react';

import fence3d from '../../../images/new-top-panel/fence-3d.png';

const FenceCalculatorCard = () => {
  return (
    <a href={`${process.env.FENCEQUOTING_HOST}/`} className="no-underline text-current font-normal hover:text-current">
      <div className="group/fence-card self-stretch w-[432px] rounded-roundings-xs bg-gray-6 flex flex-row items-center justify-start py-[42px] pr-[30px] pl-[42px] box-border relative gap-[0px_30px] font-medium-text-regular">
        <img className="w-[126px] absolute m-0 top-[27px] left-[277px] h-[146px] object-cover z-0 transition-transform ease-out duration-300 scale-100 group-hover/fence-card:scale-[130%]" alt="" src={fence3d} />
        <div className="flex-1 flex flex-col items-start justify-start gap-[16px_0px] z-1">
          <div className="self-stretch flex flex-col items-start justify-start gap-[4px_0px]">
            <div className="self-stretch relative leading-[24px]">Fence Calculator</div>
            <div className="w-[187px] relative text-sm leading-[20px] text-gray-1 inline-block">Build your dream fence and get an estimate instantly</div>
          </div>
          <div className="bg-base-white-100 group-hover/fence-card:bg-blue-1 rounded-roundings-xs box-border h-6 flex flex-row items-center justify-center py-1 px-2 gap-[5px] text-xs border-[1px] border-solid border-blue-1 transition-colors ease-out duration-200">
            <div className="flex flex-row items-start justify-start pt-0 px-0 pb-0.5">
              <div className="relative leading-[16px] font-semibold group-hover/fence-card:text-white text-blue-1 font-button-small">Go to 3D calculator</div>
            </div>
            <svg className="w-3.5 h-3.5 transition-colors ease-out duration-200" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd" d="M7.62944 3.50034C7.90906 3.21053 8.37067 3.20228 8.66047 3.4819L11.7629 6.47526C11.9053 6.61268 11.9857 6.80209 11.9857 7C11.9857 7.19791 11.9053 7.38732 11.7629 7.52474L8.66047 10.5181C8.37067 10.7977 7.90906 10.7895 7.62944 10.4997C7.34982 10.2099 7.35808 9.74825 7.64788 9.46863L9.4507 7.72917H3.74357C3.34086 7.72917 3.0144 7.40271 3.0144 7C3.0144 6.59729 3.34086 6.27083 3.74357 6.27083H9.4507L7.64788 4.53137C7.35808 4.25175 7.34982 3.79014 7.62944 3.50034Z" className="group-hover/fence-card:fill-white" fill="#0970CE" />
            </svg>
          </div>
        </div>
      </div>
    </a>
  );
};

export const FenceCalculatorCardTablet = () => {
  return (
    <a href={`${process.env.FENCEQUOTING_HOST}/`} className="group/fence-card  self-stretch rounded-roundings-xs bg-gray-6 h-[152px] flex flex-row items-center justify-start py-0 pr-2.5 pl-[22px] box-border relative gap-[30px] no-underline text-current font-normal hover:text-current">
      <img className="w-[103px] absolute !m-[0] top-[16px] left-[219px] h-[119.51px] object-cover z-[0] transition-transform ease-out duration-300 scale-100 group-hover/fence-card:scale-[130%]" alt="" src={fence3d} />
      <div className="flex-1 flex flex-col items-start justify-start gap-[16px_0px] z-1">
        <div className="self-stretch flex flex-col items-start justify-start gap-[4px_0px]">
          <div className="self-stretch relative leading-[24px]">Fence Calculator</div>
          <div className="w-[187px] relative text-sm leading-[20px] text-gray-1 inline-block">Build your dream fence and get an estimate instantly</div>
        </div>
        <div className="bg-base-white-100 group-hover/fence-card:bg-blue-1 rounded-roundings-xs box-border h-6 flex flex-row items-center justify-center py-1 px-2 gap-[5px] text-xs border-[1px] border-solid border-blue-1 transition-colors ease-out duration-200">
          <div className="flex flex-row items-start justify-start pt-0 px-0 pb-0.5">
            <div className="relative leading-[16px] font-semibold group-hover/fence-card:text-white text-blue-1 font-button-small">Go to 3D calculator</div>
          </div>
          <svg className="w-3.5 h-3.5 transition-colors ease-out duration-200" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M7.62944 3.50034C7.90906 3.21053 8.37067 3.20228 8.66047 3.4819L11.7629 6.47526C11.9053 6.61268 11.9857 6.80209 11.9857 7C11.9857 7.19791 11.9053 7.38732 11.7629 7.52474L8.66047 10.5181C8.37067 10.7977 7.90906 10.7895 7.62944 10.4997C7.34982 10.2099 7.35808 9.74825 7.64788 9.46863L9.4507 7.72917H3.74357C3.34086 7.72917 3.0144 7.40271 3.0144 7C3.0144 6.59729 3.34086 6.27083 3.74357 6.27083H9.4507L7.64788 4.53137C7.35808 4.25175 7.34982 3.79014 7.62944 3.50034Z" className="group-hover/fence-card:fill-white" fill="#0970CE" />
          </svg>
        </div>
      </div>
    </a>
  );
};

export default FenceCalculatorCard;
