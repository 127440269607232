/* globals google */

import { Loader } from 'google-maps';
import React, { CSSProperties, LegacyRef } from 'react';
import { MarkerClusterer, Cluster } from '@googlemaps/markerclusterer';
import { GeoPosition, MapComponentProps, MapComponentState, Marker, MarkerData, MarkerIcon, Polygon } from './types';
import { addFields, getClusterSVG } from './utils';
import { BIG_CLUSTER_ICON_SIZE, BLUE_0, BLUE_1, MAX_MARKERS, SMALL_CLUSTER_ICON_SIZE } from './constant';

export const ERG_MAP_COMPONENT_LIBRARIES = ['geometry'];

export default class MapComponent extends React.Component<MapComponentProps, MapComponentState> {
  private mapElement: HTMLElement | null;
  // @ts-ignore
  private kmlLayer: google.maps.KmlLayer | null;
  private readonly markers: Marker[];
  private readonly polygons: Polygon[];
  private readonly onResize: OmitThisParameter<() => void>;
  private readonly setMapElement: (element: HTMLElement) => void;
  private legendPositions: google.maps.ControlPosition | undefined;
  private legendRef: LegacyRef<HTMLImageElement> | undefined;
  private map: google.maps.Map | undefined;
  private mapBounds: google.maps.LatLngBounds | undefined;
  private onTilesLoaded: google.maps.MapsEventListener | undefined;
  private currentPopup: HTMLElement | undefined;
  constructor(props: MapComponentProps) {
    super(props);
    this.mapElement = null;
    this.legendElement = null;
    this.kmlLayer = null;
    this.markers = [];
    this.polygons = [];
    this.onResize = this.handleResize.bind(this);
    // eslint-disable-next-line complexity
    this.setMapElement = (element: HTMLElement) => {
      // On unmounting ref will be set to null using this callback
      this.mapElement = element;
      if (!this.mapElement) return;
      const { loadGoogleMapsLibrary, language, apiKey } = this.props;
      this.legendRef = React.createRef();
      if (!this.isFragment()) this.setMapSize();
      const initGoogleMap = () => {
        if (!this.mapElement) return;
        this.initMap();
      };
      if (!(loadGoogleMapsLibrary && loadGoogleMapsLibrary.load)) {
        if (!window.google) {
          const options = {
            version: 'weekly',
            libraries: ERG_MAP_COMPONENT_LIBRARIES,
            language,
          };
          const loader = new Loader(apiKey, options);
          loader.load().then(google => {
            window.google = google;
            initGoogleMap();
          });
        } else {
          initGoogleMap();
        }
      } else {
        loadGoogleMapsLibrary
          .load()
          .then(() => initGoogleMap())
          .catch((error: Error) => console.error(error));
      }
    };
    this.state = { ready: false, width: 0, height: 0 };
    // @ts-ignore
    window.onInitMap = () => this.initMap && this.initMap();
  }

  static defaultProps = {
    aspectRatio: '16:9',
    title: '',
    language: 'en',
    type: 'roadmap',
    description: '',
    markers: [],
    polygons: [],
    styles: [],
    controls: {},
    legend: {},
    apiKey: '',
    kml: {},
    mapElementClass: '',
    center: { lat: 37, lng: -122 },
    zoom: 6,
    loadingPlaceholder: <h2>Loading</h2>,
    popupBehaviour: 'stay',
    fitBy: 'height',
    loadGoogleMapsLibrary: {},
    clustering: false,
    rememberZoomCenter: false,
    isPublicAdmin: false,
    clusterColorSmall: BLUE_1,
    clusterColorBig:BLUE_0,
  };
  isFragment() {
    return this.props.fitBy === 'none';
  }
  setMapSize() {
    const ratioParts = this.props.aspectRatio.split(':').map(parseFloat);
    const ratio = ratioParts[0] / ratioParts[1];
    const isFitByHeight = this.props.fitBy === 'height';
    if (isFitByHeight) {
      const height = this.mapElement!.clientHeight;
      const width = Math.floor(height * ratio);
      this.setState({ width, height });
    } else {
      const width = this.mapElement!.clientWidth;
      const height = Math.floor(width / ratio);
      this.setState({ width, height });
    }
  }
  handleResize() {
    this.setMapSize();
    google.maps.event.trigger(this.map, 'resize');
    this.fitMapContents();
  }
  /*
   * @description Reads sessionStorage to get zoom/lat/lng values and sets them to our component,
   * this will update the component only if we have sessionStorage values.
   */
  hasPreviousZoomLatLng() {
    const zoom = sessionStorage.getItem('zoom');
    const lat = sessionStorage.getItem('lat');
    const lng = sessionStorage.getItem('lng');
    if (zoom && lat && lng) {
      this.map!.setZoom(parseInt(zoom, 10));
      this.map!.setCenter({ lat: parseFloat(lat), lng: parseFloat(lng) });
    }
  }
  /*
   * @description Triggers google.maps.event.addListener for zoom_changed and center_change
   * and saves the values on sessionStorage
   */
  handleZoomCenterChanged() {
    // @ts-ignore
    google.maps.event.addListener(this.map, 'zoom_changed', () => {
      sessionStorage.setItem('zoom', String(this.map!.getZoom()));
    });

    // @ts-ignore
    google.maps.event.addListener(this.map, 'center_changed', () => {
      sessionStorage.setItem('lat', String(this.map!.getCenter().lat()));
      sessionStorage.setItem('lng', String(this.map!.getCenter().lng()));
    });
  }
  componentWillUnmount() {
    const { rememberZoomCenter } = this.props;
    if (!this.isFragment()) window.removeEventListener('resize', this.onResize);
    if (typeof google === 'undefined') {
      return;
    }
    google.maps.event.removeListener(this.onTilesLoaded!);
    this.mapBounds = new google.maps.LatLngBounds(); // reset map bounds
    this.clearMapInstances(this.markers);
    this.clearMapInstances(this.polygons);
    if (rememberZoomCenter) {
      // @ts-ignore
      google.maps.event.removeListener('zoom');
      // @ts-ignore
      google.maps.event.removeListener('center');
      sessionStorage.clear();
    }
  }

  renderMapFragment(style = {}) {
    const { loadingPlaceholder, mapElementClass } = this.props;
    const { ready } = this.state;
    const toolbarStyle = { display: 'none' };
    const loadingStyle = {
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    };
    const mapStyle = { ...style };
    if (!ready) {
      // @ts-ignore
      mapStyle.visibility = 'hidden'; // preserve space for map
    }
    return (
      <React.Fragment>
        {!ready && <div style={loadingStyle}>{loadingPlaceholder}</div>}
        <div
          // @ts-ignore
          ref={this.setMapElement}
          style={mapStyle}
          className={mapElementClass}
        />
        <div style={toolbarStyle}>
          <img ref={this.legendRef} />
        </div>
      </React.Fragment>
    );
  }

  // eslint-disable-next-line complexity
  render() {
    if (this.isFragment()) return this.renderMapFragment();
    const containerStyle: CSSProperties = {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    };
    const { width, height, ready } = this.state;
    const { title, description, isPublicAdmin } = this.props;
    const mapStyle = { width: '100%', height: '100%' };
    if (height) mapStyle.height = `${height}px`;
    if (width) mapStyle.width = `${width}px`;
    if (isPublicAdmin) {
      // current implementation needs a static height on public admin
      // setting it to 99.9vh should render our maps, when visualizing them
      // fullscreen (ex. postcards) it should hide scrollbars
      mapStyle.height = '99.9vh';
      mapStyle.width = '100%'; // make it elastic
    }

    return (
      <div style={containerStyle}>
        {ready && title && <h2>{title}</h2>}
        {ready && description && <h3>{description}</h3>}
        {this.renderMapFragment(mapStyle)}
      </div>
    );
  }
  // eslint-disable-next-line complexity
  fitMapContents() {
    const { center: defaultCenter } = MapComponent.defaultProps;
    const { center, zoom, markers, polygons } = this.props;
    const { lat, lng } = center;
    const isCustomCenterSet = lat !== defaultCenter.lat || lng !== defaultCenter.lng;
    if (isCustomCenterSet) {
      const markersPoints = markers.map(marker => {
        return marker.position;
      });
      const polygonsPoints = polygons
        .map(polygon => {
          return polygon.vertices;
        })
        .flat();
      const points = markersPoints.concat(polygonsPoints);
      // For each point add opposite direction point so bounds symmetrical on cental point
      points.forEach((point?: GeoPosition) => {
        if (point?.lat === center.lat && point?.lng === center.lng) return;
        // Mid point equation for two points used
        const lat = 2 * center.lat() - Number(point?.lat());
        const lng = 2 * center.lng() - Number(point?.lng());
        this.mapBounds!.extend({ lat, lng });
      });
    }
    if (!this.mapBounds!.isEmpty()) {
      if (markers.length === 1 && polygons.length === 0) {
        this.map!.setZoom(zoom);
        this.map!.setCenter(this.mapBounds!.getCenter());
      } else {
        this.map!.fitBounds(this.mapBounds!);
        this.map!.panToBounds(this.mapBounds!);
      }
    }
  }
  closePopupsOnClick() {
    return this.props.popupBehaviour === 'close';
  }
  clearMapInstances(instances: object[]) {
    while (instances.length) {
      const instance = instances.pop();
      // @ts-ignore
      google.maps.event.clearInstanceListeners(instance);
      // @ts-ignore
      instance.infoWindow = null;
      // @ts-ignore
      instance.setMap(null);
    }
  }
  setMarkers() {
    this.props.markers.forEach(marker => {
      const position = marker.position;
      const iconURL = marker.icon;
      const markerData: MarkerData = {
        position,
        map: this.map,
      };
      const iconScaledSize = marker.iconScaledSize;
      if (iconURL) {
        const icon: MarkerIcon = { url: iconURL };
        if (iconScaledSize) {
          icon.scaledSize = new google.maps.Size(
            iconScaledSize,
            iconScaledSize,
          );
        }
        markerData.icon = icon;
      }
      const fieldsToCopy = ['label', 'title'];
      addFields(marker, markerData, fieldsToCopy);
      const mapMarker = new google.maps.Marker(markerData);
      this.markers.push(mapMarker);
      mapMarker.addListener('click', () => {
        if (this.closePopupsOnClick() && this.currentPopup) {
          // @ts-ignore
          this.currentPopup.close();
        }
        const markerInfo = marker.info;
        if (!markerInfo) {
          return;
        }
        // @ts-ignore
        if (!mapMarker.infoWindow) {
          // @ts-ignore
          mapMarker.infoWindow = new google.maps.InfoWindow({
            content: markerInfo,
          });
        }
        // @ts-ignore
        mapMarker.infoWindow.open(this.map, mapMarker);
        // @ts-ignore
        this.currentPopup = mapMarker.infoWindow;
      });
      this.mapBounds?.extend(position!);
    });

    if (this.props.clustering) {
      new MarkerClusterer({
        map: this.map,
        markers: this.markers,
        renderer: {
          render: this.getClustererRender,
        },
      });
    }
  }
  getClusterColor = (isBigClusterIcon: boolean) => {
    if (this.props.clusterColor) return this.props.clusterColor;

    if (isBigClusterIcon) return this.props.clusterColorBig;

    return this.props.clusterColorSmall;
  };
  getClustererRender = (
    { count, position }: Cluster,
  ): google.maps.Marker => {
    // if this cluster has more markers greater than 9
    const isBigClusterIcon = count > MAX_MARKERS;
    const color = this.getClusterColor(isBigClusterIcon);
    const scaledSize = isBigClusterIcon ? BIG_CLUSTER_ICON_SIZE : SMALL_CLUSTER_ICON_SIZE;

    // create svg url with fill color
    const svg = getClusterSVG(color);

    // create marker using svg icon
    return new google.maps.Marker({
      position,
      icon: {
        url: `data:image/svg+xml;base64,${svg}`,
        scaledSize: new google.maps.Size(scaledSize, scaledSize),
      },
      label: {
        text: String(count),
        color: 'rgba(255,255,255,0.9)',
        fontSize: '15px',
        fontWeight: 'bold',
      },
      // adjust zIndex to be above other markers
      zIndex: Number(google.maps.Marker.MAX_ZINDEX) + count,
    });
  };
  orderPolygonsByArea() {
    let radiusOffset = 0;
    const polygonsWithAreas = this.polygons.map(polygon => {
      const area = polygon.radius ?
        (polygon.radius + radiusOffset++) * 2 * Math.PI :
        google.maps.geometry.spherical.computeArea(polygon.getPath());
      return { polygon, area };
    });
    polygonsWithAreas.sort((a, b) => {
      return Math.floor(b.area) - Math.floor(a.area);
    });
    let zIndex = 0;
    polygonsWithAreas.forEach(polygonWithArea => {
      polygonWithArea.polygon.setOptions({ zIndex: zIndex++ });
      if (zIndex == google.maps.Marker.MAX_ZINDEX) {
        zIndex = 0;
      }
    });
  }
  setPolygons() {
    const defaultColor = '#000000';
    const defaultFontSize = '16px';
    const defaultRadius = 50;
    const mapDiv = this.map?.getDiv();
    // eslint-disable-next-line complexity
    this.props.polygons.forEach(polygon => {
      const label = polygon.label;
      const vertices = polygon.vertices;
      const isSingleVertex = vertices.length == 1;
      const polygonData: {
        map?: google.maps.Map,
        center?: GeoPosition,
        radius?: number,
        paths?: GeoPosition[],
      } = {
        map: this.map,
        center: undefined,
        radius: undefined,
        paths: undefined,

      };
      const fieldsToCopy = [
        'strokeWeight',
        'strokeOpacity',
        'strokeColor',
        'fillColor',
        'fillOpacity',
      ];
      addFields(polygon, polygonData, fieldsToCopy);
      if (isSingleVertex) {
        polygonData.center = vertices[0];
        polygonData.radius = defaultRadius;
      } else {
        polygonData.paths = vertices;
      }
      const mapPolygon = isSingleVertex ?
        new google.maps.Circle(polygonData) :
        new google.maps.Polygon(polygonData);
      // @ts-ignore
      this.polygons.push(mapPolygon);
      mapPolygon.addListener('click', event => {
        if (this.closePopupsOnClick() && this.currentPopup) {
          // @ts-ignore
          this.currentPopup.close();
        }
        const polygonInfo = polygon.info;
        if (!polygonInfo) {
          return;
        }
        // @ts-ignore
        if (!mapPolygon.infoWindow) {
          // @ts-ignore
          mapPolygon.infoWindow = new google.maps.InfoWindow({
            content: polygonInfo,
          });
        }
        // @ts-ignore
        mapPolygon.infoWindow.setPosition(event.latLng);
        // @ts-ignore
        mapPolygon.infoWindow.open(this.map);
        // @ts-ignore
        this.currentPopup = mapPolygon.infoWindow;
      });
      if (isSingleVertex) {
        // @ts-ignore
        this.mapBounds.extend(mapPolygon.getCenter());
        if (label) {
          mapPolygon.addListener('mouseover', () => {
            // @ts-ignore
            mapDiv.setAttribute('title', label);
          });
          mapPolygon.addListener('mouseout', () => {
            // @ts-ignore
            mapDiv.removeAttribute('title');
          });
        }
      } else {
        const polygonBounds = new google.maps.LatLngBounds();
        // @ts-ignore
        mapPolygon && mapPolygon.getPaths().forEach(path => {
          path.forEach((vertex: GeoPosition) => {
            polygonBounds.extend(vertex);
            this.mapBounds!.extend(vertex);
          });
        });
        const polygonCenter = polygonBounds.getCenter();
        if (label) {
          const polygonLabelMarker = new google.maps.Marker({
            position: polygonCenter,
            label: {
              text: label,
              color: defaultColor,
              fontSize: defaultFontSize,
            },
            map: this.map,
            // @ts-ignore
            icon: {
              path: google.maps.SymbolPath.CIRCLE,
              scaledSize: 0,
            },
          });
          this.markers.push(polygonLabelMarker);
        }
      }
    });
    this.orderPolygonsByArea();
  }
  // eslint-disable-next-line complexity
  setLegend() {
    const legend = this.props.legend;
    // @ts-ignore
    const legendURL = legend.image;
    // @ts-ignore
    const position = legend.position;
    if (!legendURL && !position) return;
    if (!legendURL) {
      console.error('Map missing legend image but has position');
      return;
    }
    if (!position) {
      console.error('Map missing legend position but has image');
      return;
    }
    const legendPosition =
      // @ts-ignore
      this.legendPositions[position] || google.maps.ControlPosition.TOP_RIGHT;
    // @ts-ignore
    const legendElement = this.legendRef.current;
    // @ts-ignore
    legendElement.src = legendURL;
    // @ts-ignore
    this.map.controls[legendPosition].push(legendElement);
  }
  initKmlLayer(
    url: string,
    suppressInfoWindows?: bool = false,
    preserveViewport?: bool = true,
  ) {
    this.kmlLayer = new google.maps.KmlLayer({ url, suppressInfoWindows, preserveViewport });
    this.kmlLayer.setMap(this.map);
  }
  initMap() {
    // @ts-ignore
    this.legendPositions = {
      TOP_LEFT: google.maps.ControlPosition.TOP_LEFT,
      TOP_RIGHT: google.maps.ControlPosition.TOP_RIGHT,
      BOTTOM_LEFT: google.maps.ControlPosition.BOTTOM_LEFT,
      BOTTOM_RIGHT: google.maps.ControlPosition.BOTTOM_RIGHT,
      STRETCH_TOP: google.maps.ControlPosition.TOP_CENTER,
      STRETCH_BOTTOM: google.maps.ControlPosition.BOTTOM_CENTER,
    };
    // @ts-ignore
    this.currentPopup = null;
    this.mapBounds = new google.maps.LatLngBounds();
    const {
      center,
      zoom,
      styles,
      type,
      controls,
      rememberZoomCenter,
      kml,
    } = this.props;
    const mapOptions: google.maps.MapOptions = {
      center,
      zoom,
      styles,
      mapTypeId: type,
      // @ts-ignore
      ...controls,
    };
    // @ts-ignore
    this.map = new google.maps.Map(this.mapElement, mapOptions);
    this.map.setTilt(0);
    const { url, suppressInfoWindows, preserveViewport } = kml;
    if (url) {
      this.initKmlLayer(url, suppressInfoWindows, preserveViewport);
    } else {
      this.setMarkers();
      this.setPolygons();
    }
    this.fitMapContents();
    this.props.legend && this.setLegend();
    if (!this.isFragment()) window.addEventListener('resize', this.onResize);
    this.onTilesLoaded = google.maps.event.addListener(
      this.map,
      'tilesloaded',
      () => {
        if (!this.state.ready) {
          this.setState({ ready: true });
          if (rememberZoomCenter) {
            // set previous zoom/center sessionStorage values if any
            this.hasPreviousZoomLatLng();
            // begin listening for zoom/center actions so we can save them
            this.handleZoomCenterChanged();
          }
        }
      },
    );
  }

  // @ts-ignore
  // eslint-disable-next-line complexity
  componentDidUpdate(prevProps) {
    if (!this.state.ready) return;
    const { polygons, markers } = this.props;
    const polygonsMatch =
      JSON.stringify(polygons) == JSON.stringify(prevProps.polygons);
    const markersMatch =
      JSON.stringify(markers) == JSON.stringify(prevProps.markers);
    if (
      this.polygons.length &&
      polygonsMatch &&
      this.markers.length &&
      markersMatch
    )
      return;
    this.mapBounds = new google.maps.LatLngBounds(); // reset map bounds
    this.clearMapInstances(this.markers);
    this.setMarkers();
    this.clearMapInstances(this.polygons);
    this.setPolygons();
    this.fitMapContents();
  }
}
