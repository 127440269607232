export const addFields = (source: object, destination: object, fields: string[]) => {
  fields.forEach(field => {
    // @ts-ignore
    const value = source[field];
    if (value !== 'undefined') {
      // @ts-ignore
      destination[field] = value;
    }
  });
};

export const getClusterSVG = (color?: string) => {
  return window.btoa(`
    <svg fill="${color}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240">
      <circle cx="120" cy="120" opacity=".6" r="70" />
      <circle cx="120" cy="120" opacity=".3" r="90" />
      <circle cx="120" cy="120" opacity=".2" r="110" />
    </svg>
  `);
};
