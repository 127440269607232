'use client';
import React from 'react';

import Notification from '@ergeon/core-components/exports/Notification';

import { showUpcomingFeatures } from './utils';

const ShowUpcomingFeaturesComponent = () => {

  const shouldShowUpcomingFeatures = showUpcomingFeatures('ENG-1XX');
  const isWindow =  typeof window !== 'undefined';

  if (shouldShowUpcomingFeatures && isWindow) {
    return (
      <div className="upcoming-features-wrapper">
        <Notification type="Information">
          <div className="h6 additional-header">Experimental mode</div>
          Upcoming Features are shown
        </Notification>
      </div>
    );
  }
  return null;

};

export default ShowUpcomingFeaturesComponent;
