import React, { useCallback, useEffect, useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { navigate } from 'gatsby';
// eslint-disable-next-line import/no-unresolved
import { useLocation } from '@reach/router';
import Toggle from '@ergeon/core-components/exports/Toggle';
import ToggleItem from '@ergeon/core-components/exports/ToggleItem';

import { trackVisitorClick } from '../utils/analytics/trackVisitorClick';

import { availableLocales, Locale } from './config';
import 'moment/locale/es'; // use localization in moment too

const items: ToggleItem<Locale>[] = availableLocales.map((locale) => ({
  label: locale.toLocaleLowerCase(),
  value: locale,
}));

type LocaleToggleProps = {
  className?: string;
};

const CLICK_ACTION = 'pro_page_locale_toggle';

const LocaleToggle = ({ className }: LocaleToggleProps) => {
  const { i18n } = useTranslation();
  const location = useLocation();

  const language = useMemo(() => location.pathname.replace(/^.+\//, ''), [location]);

  const gaTrackClicks = useCallback((clickText: string) => {
    trackVisitorClick(clickText, CLICK_ACTION);
  }, []);

  const onChange = useCallback(
    (value: Locale) => {
      if (value !== i18n.language) {
        i18n.changeLanguage(value);
        gaTrackClicks(value);

        if (value === 'es') {
          navigate(`/pro/${value}`);
        } else {
          navigate('/pro/');
        }

      }
    },
    [i18n, gaTrackClicks],
  );

  useEffect(
    function changeLanguageFromParam() {
      if (language !== i18n.language) {
        i18n?.changeLanguage(language);
      }
    },
    [i18n.language, language],
  );

  return <Toggle {...{ className, items, onChange, value: i18n.language, 'data-testid': 'toggle-switch' }} />;
};

export default LocaleToggle;
