
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import moment from 'moment';
import 'moment/locale/es'; // use localization in moment too

import enJSON from './en.json';
import esJSON from './es.json';
import { defaultLocale, Locale } from './config';

export default function initI18n() {
  // eslint-disable-next-line import/no-named-as-default-member
  i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      debug: process.env.NODE_ENV === 'development',
      fallbackLng: defaultLocale,
      interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
      },
      resources: {
        en: {
          translation: enJSON,
        },
        es: {
          translation: esJSON,
        },
      },
    });

  i18n.on('languageChanged', (lng: Locale) => {
    moment.locale(lng);
  });
  moment.locale(defaultLocale);
}
