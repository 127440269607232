import React, { memo, useMemo } from 'react';

// eslint-disable-next-line import/no-unresolved
import { useLocation } from '@reach/router';
import SimpleFooter from '@ergeon/core-components/exports/SimpleFooter';

import { City } from '../../layouts/types';
import { formatFooterLicenses } from '../../utils/utils';
import { DEFAULT_ADDRESS, LICENSES } from '../../utils/constants';

type LocationsList = {
  text: string;
  url: string;
};

type AppFooterProps = {
  city?: City;
  ergeonUrl: string;
  fencequotingUrl: string;
  locationsList: LocationsList[];
  widthClass: string;
};

enum FooterView {
  Home,
  Cities,
  Default,
}

const AppFooter = ({ city, locationsList, ...footerProps }: AppFooterProps) => {
  const location = useLocation();

  const urlMatch = useMemo(() => {
    if (location.pathname.match(/^\/$/)) {
      return FooterView.Home;
    } else if (location.pathname.match(/\/fences\/cities\//i)) {
      return FooterView.Cities;
    }
    return FooterView.Default;
  }, [location.pathname]);

  switch (urlMatch) {
    case FooterView.Home:
      return (
        <SimpleFooter
          {...footerProps}
          appEnv={process.env.NODE_ENV}
          isLocationFooter
          licenses={LICENSES}
          locationFooterArray={locationsList}
          locationFooterTitle="Locations"
          withTreeLogo
        />
      );
    case FooterView.Cities:
      return (
        <SimpleFooter
          {...footerProps}
          address={city?.address || DEFAULT_ADDRESS}
          appEnv={process.env.NODE_ENV}
          licenses={formatFooterLicenses(city?.licenses, city?.license?.url)}
          phoneNumber={city?.phone}
        />
      );
    default:
      return <SimpleFooter {...footerProps} appEnv={process.env.NODE_ENV} />;
  }
};

export default memo(AppFooter);
