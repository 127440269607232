// @ts-ignore
import { getCurrentData, getVisitorId } from '@ergeon/erg-utms';
import * as Sentry from '@sentry/browser';

import { trackError } from './trackError';

const isObject = (value: string | object | null) => {
  const type = typeof value;
  return value != null && (type === 'object' || type === 'function');
};

const addCampaignParams = (params: object) => {
  const currentData = getCurrentData();
  return { ...params, ...currentData['utm'], ...currentData['document'] };
};
export const identify = (_gidOrTraits: object | string, _traits?: object) => {
  let traits;

  if (isObject(_gidOrTraits)) {
    traits = _gidOrTraits;
  } else {
    traits = _traits;
  }

  traits = addCampaignParams(traits as object || {});

  if (document.referrer) {
    traits.referrer = document.referrer;
  }

  getVisitorId()
    .then((visitorId: string) => {
      Sentry.configureScope((scope) => {
        scope.setUser({
          ...traits,
          uuid: visitorId,
        });
      });
    })
    .catch((e) => {
      trackError(e);
    });
};
