import wood from '../../images/new-top-panel/wood.png';
import vinyl from '../../images/new-top-panel/vinyl.png';
import metal from '../../images/new-top-panel/metal.png';
import chainlink from '../../images/new-top-panel/chainlink.png';

export const CARD_LINKS = [
  { label: 'Wood', path: '/fences/wood', image: wood },
  { label: 'Metal', path: '/fences/metal', image: metal },
  { label: 'Vinyl', path: '/fences/vinyl', image: vinyl },
  { label: 'Chain Link', path: '/fences/chain-link', image: chainlink },
];

export const LINKS = [
  { label: 'Artificial grass', path: '/artificial-grass' },
  { label: 'Deck', path: '/decks' },
  { label: 'Commercial', path: '/fences/commercial' },
  { label: 'Blog', path: '/blog' },
];
