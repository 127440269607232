'use client';

import React, { useCallback, useEffect, useMemo, useState } from 'react';

import {
  TopPanelNav, TopPanelNavItemLink, TopPanelNavItemCTA,
} from '@ergeon/core-components/exports/UniversalTopPanel';
import { ReactComponent as IconPhone } from '@ergeon/core-components/src/assets/icon-phone-orange.svg';
import { ReactComponent as IconPricing } from '@ergeon/core-components/src/assets/icon-pricing.svg';
import { ReactComponent as IconUser } from '@ergeon/core-components/src/assets/icon-user-signin.svg';
import Spinner from '@ergeon/core-components/exports/Spinner';
// eslint-disable-next-line import/no-unresolved
import { useLocation } from '@reach/router';
import { navigate } from 'gatsby';

import { checkRouteList } from '../../utils/utils';
import { getAuthService } from '../../utils/auth';
import { AuthUser } from '../types';
import { HELP_PATH } from '../../utils/constants';

import TopPanelLogged from './TopPanelLogged';

interface TopPanelBaseProps {
  address: string;
  onCTAClick?: () => void;
  phone?: string;
  phoneLink?: string;
}

const DEFAULT_PHONE = '1-888-374-3661';
const SIGN_IN_PATH = '/app/sign-in';

const TopPanelBase = (props: TopPanelBaseProps) => {
  const { address, onCTAClick, phone = DEFAULT_PHONE, phoneLink } = props;
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState<AuthUser | null>(null);

  const isRequestQuotePage = useMemo(() => {
    return checkRouteList(['/request-quote'], location);
  }, [location]);

  const onClickPath = useCallback((path: string, asPath = false) => {
    path = path.toLocaleLowerCase().replaceAll(' ', '');
    const url = !asPath ? `${user?.gid}/${path}` : `${path}`;

    if (url.includes(HELP_PATH)) {
      return navigate('/help');
    }
    return window.location.href = `${process.env.CUSTOMER_APP}/app/${url}/`;
  }, [user]);

  const onClickCTA = useCallback(() => {
    if (isRequestQuotePage) {
      setLoading(false);
      return;
    }
    setLoading(true);
    onCTAClick?.();
  }, [isRequestQuotePage, onCTAClick]);

  useEffect(function watchSpinner() {
    if (isRequestQuotePage) {
      setLoading(false);
    }
  }, [loading, isRequestQuotePage]);

  useEffect(function getLoggedUser() {
    (async() => {
      try {
        const authService = getAuthService();
        const user = await authService.getUser();
        setUser(user);
      } catch (userError) {
        console.error('userError', userError);
      }
    })();
  }, []);

  return (
    <>
      <TopPanelNav data-testid="top-panel-base">
        <TopPanelNavItemLink flavor="action" href={address}>
          <IconPricing className="icon-pricing icon" />
          Fence Calculator
        </TopPanelNavItemLink>
        <TopPanelNavItemLink data-track-call="true" flavor="action" href={`tel:${phoneLink}`}>
          <IconPhone className="icon-phone icon" />
          <span className="display-phone">Call us: </span>
          {phone}
        </TopPanelNavItemLink>
        <TopPanelNavItemCTA flavor="action" onClick={onClickCTA}>
          {loading ? <Spinner active={true} borderWidth={0.1} size={20} /> : 'Get a Quote'}
        </TopPanelNavItemCTA>
        {!user ? (
          <TopPanelNavItemLink data-testid="sign-in-item" flavor="action" href={SIGN_IN_PATH}>
            <IconUser className="icon-user icon" />
            Sign In
          </TopPanelNavItemLink>
        ) : (
          <TopPanelLogged user={user} onClickPath={onClickPath} />
        )}
      </TopPanelNav>
      <div className="top-panel__mobile-phone">
        <TopPanelNavItemLink data-track-call="true" flavor="action" href={`tel:${phoneLink}`}>
          <IconPhone className="icon-phone icon" />
        </TopPanelNavItemLink>
      </div>
    </>
  );
};

export default TopPanelBase;
