import { getVisitorId } from '@ergeon/erg-utms';

import { identify } from './identify';
import { page } from './page';

export const init = async() => {
  const visitorId = await getVisitorId();
  identify(visitorId);
  page();
};
