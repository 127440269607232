import { PARAMETERS_LOADED } from '../events';

interface ObjectData {
  inner_width: number;
  inner_height: number;
  initial_landing_page: string;
  initial_referrer: string;
  document_referrer: string;
  ua: string;
  engine_name: string;
  arrival_time: number;
  browser_major: string;
  cpu_architecture: string;
}

export const trackBrowserParams = (): void => {
  if (!window) return;
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const { getBaseEventData } = require('@ergeon/erg-utms');
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const { track } = require('./track');
  getBaseEventData().then((baseEventData: { object: ObjectData }) => {
    const objectData = baseEventData.object;

    const params = {
      inner_width: objectData.inner_width,
      inner_height: objectData.inner_height,
      initial_landing_page: objectData.initial_landing_page,
      initial_referrer: objectData.initial_referrer,
      document_referrer: objectData.document_referrer,
      ua: objectData.ua,
      engine_name: objectData.engine_name,
      arrival_time: objectData.arrival_time,
      browser_major: objectData.browser_major,
      cpu_architecture: objectData.cpu_architecture,
    };

    track(PARAMETERS_LOADED, params);
  })
    .catch((error: Error) => {
      console.error('Error in trackBrowserParams:', error);
    });
};
