import { RequestAPI } from "./utils";

export type IPRequestResponse = {
  city: string;
  country_code: string;
  ip: string;
  latitude: number;
  longitude: number;
  proxy: string | null;
  reason: string | null;
  state: string;
  state_code: string;
  success: boolean;
  zip_code: number | null;
};

export type IPAddressLookupReturnType = {
  initial_visitor_IP: IPRequestResponse["ip"];
  initial_ip_zipcode: IPRequestResponse["zip_code"];
  initial_ip_city: IPRequestResponse["city"];
  initial_ip_state: IPRequestResponse["state"];
};

async function IPAddressLookup(url: string): Promise<IPAddressLookupReturnType> {
  const { ip, city, zip_code, state } = (await RequestAPI(url)) as IPRequestResponse;
  const value = {
    initial_visitor_IP: ip,
    initial_ip_zipcode: zip_code,
    initial_ip_city: city,
    initial_ip_state: state,
  };
  return value;
}

export default IPAddressLookup;
