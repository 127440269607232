import React from 'react';

import { Link, GatsbyLinkProps } from 'gatsby';
// eslint-disable-next-line import/no-unresolved
import { Location } from '@reach/router';

interface CustomLinkProps extends GatsbyLinkProps<object> {
  activeClassName: string;
}

const CustomLink = ({ to, activeClassName, children, ...other }: CustomLinkProps) => {
  // Extract the ref from other
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { ref, ...rest } = other;
  return (
    <Location>
      {({ location }) => {
        const pathname: string = location.pathname;
        const isActive = pathname.includes(to);
        const className = isActive ? `${activeClassName} ${rest.className || ''}` : rest.className;
        return (
          <Link to={to} className={className} {...rest}>
            {children}
          </Link>
        );
      }}
    </Location>
  );
};

export default CustomLink;
