import {v4 as uuidv4} from 'uuid';

import {getInitialData} from './UTMData';
import { VISITOR_ID_KEY } from '../constants';

const UUID_V4_MASK = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx';

function uuidv4Fallback() {
  return UUID_V4_MASK.replace(/[xy]/g, function(c) {
    const r = Math.random() * 16 | 0, v = c == "x" ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}

const VisitorID = {
  async get() {
    const initialData = await getInitialData();
    return initialData[VISITOR_ID_KEY];
  },
  generate() {
    if(process.env.NODE_ENV === 'test') {
      return UUID_V4_MASK;
    }
    try {
      return uuidv4();
    } catch (e) {
      return uuidv4Fallback();
    }
  },
};

export default VisitorID;
