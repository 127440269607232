import { useCallback } from 'react';

// eslint-disable-next-line import/no-unresolved
import { useLocation } from '@reach/router';

interface useRegularMenuItemsProps {
  asPath?: boolean;
  path?: string
  onClickPath?: (path: string, asPath?: boolean) => void;
  label: string;
  icon: JSX.Element;
}
const useRegularMenuItems = (props: useRegularMenuItemsProps) => {
  const { label, onClickPath, icon, asPath, path } = props;
  const location = useLocation();

  const onClick = useCallback(() => {
    const url = path ? path : label;
    onClickPath?.(url, asPath);
  }, [onClickPath, label, asPath]);

  const setActiveClassName = useCallback((path: string) => {
    if (location.pathname.includes(path?.toLocaleLowerCase())) {
      return 'active-menu-item';
    }
    return '';
  }, [location]);

  return {
    className: setActiveClassName(label),
    icon,
    label,
    onClick,
  };
};

export default useRegularMenuItems;
