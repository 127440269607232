(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("localforage"), require("query-string"), require("localforage-memoryStorageDriver"), require("localforage-sessionstoragewrapper"), require("postmate"), require("slugify"), require("localforage-getitems"), require("localforage-setitems"), require("uuid"), require("ua-parser-js"), require("is-plain-object"), require("clean-deep"));
	else if(typeof define === 'function' && define.amd)
		define(["localforage", "query-string", "localforage-memoryStorageDriver", "localforage-sessionstoragewrapper", "postmate", "slugify", "localforage-getitems", "localforage-setitems", "uuid", "ua-parser-js", "is-plain-object", "clean-deep"], factory);
	else {
		var a = typeof exports === 'object' ? factory(require("localforage"), require("query-string"), require("localforage-memoryStorageDriver"), require("localforage-sessionstoragewrapper"), require("postmate"), require("slugify"), require("localforage-getitems"), require("localforage-setitems"), require("uuid"), require("ua-parser-js"), require("is-plain-object"), require("clean-deep")) : factory(root["localforage"], root["query-string"], root["localforage-memoryStorageDriver"], root["localforage-sessionstoragewrapper"], root["postmate"], root["slugify"], root["localforage-getitems"], root["localforage-setitems"], root["uuid"], root["ua-parser-js"], root["is-plain-object"], root["clean-deep"]);
		for(var i in a) (typeof exports === 'object' ? exports : root)[i] = a[i];
	}
})(window, function(__WEBPACK_EXTERNAL_MODULE__6__, __WEBPACK_EXTERNAL_MODULE__13__, __WEBPACK_EXTERNAL_MODULE__18__, __WEBPACK_EXTERNAL_MODULE__19__, __WEBPACK_EXTERNAL_MODULE__20__, __WEBPACK_EXTERNAL_MODULE__32__, __WEBPACK_EXTERNAL_MODULE__35__, __WEBPACK_EXTERNAL_MODULE__36__, __WEBPACK_EXTERNAL_MODULE__37__, __WEBPACK_EXTERNAL_MODULE__38__, __WEBPACK_EXTERNAL_MODULE__39__, __WEBPACK_EXTERNAL_MODULE__40__) {
return 