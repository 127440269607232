import { ReactElement } from 'react';

type Layout = {
  phoneNumber: string;
};

export interface Model {
  id: number;
}

export interface Collection extends Array<Model> { }

export type Params = {
  secret: string;
  path: string;
  quoteId?: string;
};

export type SignatureType = 'draw' | 'text';

export type SignatureData = {
  value: string;
  type: SignatureType;
  signedDate: string;
  signedPDF?: string;
};

export type OrderData = {
  orderId: string;
  customerName: string;
  customerAddress: string;
  quoteDate: string;
};

export type Customer = {
  id: number;
  user_id: number;
  gid: string;
  full_name: string;
  email: string;
  phone_number: string;
  main_address: Address;
};

export type SignoffBalance = {
  project_cost: number;
  pre_payments: number;
  discounts: number;
  subtotal: number;
  payment_fees: number;
  payments: number;
  total: number;
};

export interface QuoteApproval {
  id: number;
  customer: Customer;
  is_primary: boolean;
  total_price: string;
  reviewed_at: string;
  approved_at: unknown;
  secret: string;
  quote: Quote;
  quote_approval_lines: QuoteLine[];
  other_quote_approvals: unknown[];
  new_quote_approval: Quote | null;
  last_approved_project_revision_quote_approval: Quote | null;
  customer_pdf: string;
  contract_pdf: string;
  merged_customer_pdf: string;
  previously_approved_price: string;
  project_total_price: string;
  signoff_at: string;
  signoff_by: string;
  signoff_pdf: string;
  signoff_img: string;
  first_approved_quote_approval: {
    id: number;
    approved_at: string;
  } | null;
  signoff_balance: SignoffBalance,
  last_transaction_date: string | null;
}

export type AppCustomerQuotePageProps = {
  setPDFHeaderPhoneAction: (value: number) => void;
  layout: Layout;
  quoteFallback?: ReactElement;
};

type customerTypes = {
  full_name: string;
  approved_at: string;
  id: number;
};

export interface quoteApprovalList {
  id: number;
  customer: customerTypes;
}

export interface ExplanationSectionProps {
  asPDF: boolean;
  contractUrl: string | null;
  quoteType: string;
}

export interface ProjectDetailsProps {
  quote: {
    description_html: string;
  };
}

type QuoteApprovalProps = {
  id: number;
  customer: {
    full_name: string;
  };
  approved_at: Date;
};

export interface QuoteApprovalProp {
  quoteApproval: QuoteApprovalProps;
  idx: number;
}

export type mockExplanationConstantType = {
  heading: string;
  svgUrl: string;
  description: string;
};

export interface ContractBlockProps {
  contractUrl: string | null;
  quoteType: string;
}

export type HouseType = {
  id: number;
  address: HouseAddress;
  is_hidden?: boolean;
  has_active_order: boolean;
  customer?: Customer;
};

export type HouseAddress = {
  formatted_address: string;
  zip_code: string;
  latitude: number;
  longitude: number;
};

export type Order = {
  id: number;
  ordered_at: string | null;
  house: HouseType;
  product: OrderProduct;
  customer_deal_status: string;
  sales_rep: OrderStaffInfo | null;
  status: string;
  status_display: string;
  project_manager: OrderStaffInfo | null;
  quotes: Quote[];
  has_active_remote_onsite_appointments: boolean,
};

export type Quote = {
  id: number;
  title: string | null;
  sent_to_customer_at: string | null;
  total_price: string;
  total_cost: string;
  approved_at: string | null;
  cancelled_at: string | null;
  reviewed_at: string | null;
  description_html: string;
  order: Order;
  order_id: number;
  quote_lines: QuoteLine[];
  quote_approvals: QuoteApproval[];
  preview_quote_line: PreviewQuoteLine | null;
  secret: string;
  expires_at: string | null;
  total_length: string | null;
  total_area: string | null;
  status: QuoteStatus;
  is_scope_change?: boolean;
  replaced_by_quote?: {
    secret: string;
  };
  previously_approved_total_cost: string;
  project_total_cost: string;
  send_to_customer?: string;
  preview_image?: string;
  licenses?: QuoteLicense[];
  mediafile_list?: MediaFiles;
};

export type MediaFiles = {
  mediafiles: MediaFile[];
};

export type MediaFile = {
  file: string;
  id: number;
  title: string;
};

export type QuoteLicense = {
  validation_url: string;
  number: number;
  public_help_page_url: string;
};

export type OrderProduct = {
  id: number;
  name: string;
  short_name: string;
  slug: string;
  is_active: boolean;
};

export type QuoteStatus = {
  id: number;
  type: string;
  type_display: string;
  code: string;
  label: string;
  sequence: number;
};

export type QuoteLine = {
  id: number;
  config: QuoteLineConfig;
  property_config: null;
  label: string;
  quantity: string;
  description: string;
  unit: string;
  cost: string;
  is_quote_preview_possible: boolean;
  catalog_type: OrderType;
  display_to_customer: boolean;
  display_to_installer: boolean;
  approved_at: Date;
  quote_id: number;
  mediafile_list: null;
  price: string;
  display_price: string;
  is_dropped: boolean;
  dropped_at_quote_id: null;
  is_build_spec_available: boolean;
};

export type PreviewQuoteLine = {
  config: QuoteLineConfig;
};

export type QuoteLineConfig = {
  id: number;
  schema: string;
  code: string;
  tags: string[];
  schema_code_url: string;
};

export type OrderType = {
  item: string;
  unit: string;
  allows_unit_display: boolean;
  map_kind: string;
};

export type OrderStaffInfo = {
  id: number;
  email: string;
  full_name: string;
  phone_number: string;
};

export type Product = 'fence-replacement' | 'driveway-installation' | 'landscaping-design' | 'deck-installation';

export type Address = {
  primary_number: string;
  street_name: string;
  city_name: string;
  state_abbreviation: string;
  zipcode: string;
  location: {
    lat: number;
    lng: number;
  };
  formatted_address: string;
  raw_address: string;
  place_types: string[];
};

export type ProductAvailability = {
  zipcode: string;
  supported?: boolean;
  products: {
    [key in Product]?: boolean;
  };
  market: string;
  state: string;
};

export type Lead = {
  address?: Address;
  product_slug?: Product;
  productAvailability?: ProductAvailability;
  zipcode?: string;
};

export enum EngineType {
  LIST = 'list-based',
  RULES = 'rules-based',
}

export enum MenuItems {
  ORDERS = 'Orders',
  APPOINTMENTS = 'Appointments',
  PROFILE = 'Profile',
  ADDRESSES = 'Addresses',
  PAYMENTS = 'Payments',
  SETTINGS = 'Settings',
  HELP = 'Help',
  LOGOUT = 'Log out',
}

export type AuthUser = {
  gid: string;
  full_name?: string;
  phone_number?: string;
  email?: string;
  main_address?: {
    formatted_address: string;
  };
};
