import React from 'react';

import {
  TopPanelNav,
  TopPanelNavItem,
  TopPanelNavItemCTA,
  TopPanelNavItemLink,
} from '@ergeon/core-components/exports/UniversalTopPanel';
import { ReactComponent as IconPhone } from '@ergeon/core-components/src/assets/icon-phone-green.svg';

interface TopPanelProPageProps {
  phone?: string;
  phoneLink?: string;
  languageToggle?: JSX.Element;
  onCTAClick?(): void;
}

const DEFAULT_PHONE = '1-888-374-3661';

const TopPanelProPage = (props: TopPanelProPageProps) => {
  const { phone = DEFAULT_PHONE, phoneLink, languageToggle, onCTAClick } = props;

  return (
    <TopPanelNav>
      <TopPanelNavItemCTA flavor="primary" onClick={onCTAClick}>Join our Team</TopPanelNavItemCTA>
      <TopPanelNavItemLink
        data-track-call="true"
        flavor="primary"
        href={`tel:${phoneLink}`}
      >
        <IconPhone className="icon-phone" />
        <span className="display-phone">Call us: </span>{phone}
      </TopPanelNavItemLink>
      <TopPanelNavItem className="toggle-section">
        {languageToggle}
      </TopPanelNavItem>
    </TopPanelNav>
  );
};

export default TopPanelProPage;
