import * as queryString from 'query-string';
import UAParser from 'ua-parser-js';
import isPlainObject from 'is-plain-object';
import cleanDeep from 'clean-deep';

import {ERGEON_DOMAIN, NO_REFERRER} from '../constants';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type UTMs = Record<string, any>;

enum UTM_SOURCE {
  EMPTY = 'direct',
  UNKNOWN = 'other',
}
const UTM_SOURCE_HOST_MAP: Record<string, string> = {
  'homeadvisor.com': 'homeadvisor',
  'yelp.com': 'yelp',
  'google.': 'google',
  'facebook.com': 'facebook',
};
const UTM_MEDIUM_DEFAULT = 'website';

export function getUTMs(): UTMs {
  const parsedQuery = queryString.parse(window.location.search);
  const utmData: UTMs = {};
  // Fill in UTMs from the location.search
  for (const key in parsedQuery) {
    if (key.toLowerCase().startsWith('utm_')) {
      utmData[key.toLowerCase()] = parsedQuery[key];
    }
  }
  if (!utmData['utm_source']) {
    utmData['utm_source'] = getUTMSource();
  }
  if (!utmData['utm_medium']) {
    utmData['utm_medium'] = UTM_MEDIUM_DEFAULT;
  }
  return utmData;
}

export function getUTMSource(): string {
  if (!document.referrer) {
    // Seems to be direct visit
    return UTM_SOURCE.EMPTY;
  }
  const referrerURL = new URL(document.referrer);
  const referrerHostName = referrerURL.hostname;
  // Treat one of the known hosts as a referrer
  for (const utmHostPart in UTM_SOURCE_HOST_MAP) {
    if (referrerHostName.includes(utmHostPart)) {
      return UTM_SOURCE_HOST_MAP[utmHostPart];
    }
  }
  // Check if it’s refs self, treat as direct visit
  if (referrerHostName === window.location.hostname) {
    return UTM_SOURCE.EMPTY;
  }
  // Some different referrer we don’t strictly identify
  return UTM_SOURCE.UNKNOWN;
}

export function getDocumentParams(): UTMs {
  let referrer = document.referrer || NO_REFERRER;
  if (new RegExp(`//${ERGEON_DOMAIN}`).test(referrer)) {
    window.console.warn(
      `The referrer code can never be ${ERGEON_DOMAIN}.`,
      'This is by definition an error of losing the external initial referrer.',
    );
    referrer = 'Unknown';
  }
  return {
    referrer,
    href: window.location.href,
  };
}

export function getBrowserParams(): UTMs {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const ua: Record<string, any> = new UAParser(window.navigator.userAgent).getResult();
  const res: UTMs = {};
  for (const key in ua) {
    if (isPlainObject(ua[key])) {
      for (const deepKey in ua[key]) {
        if (ua[key][deepKey]) {
          res[`${key}_${deepKey}`] = ua[key][deepKey];
        }
      }
    } else if (ua[key]) {
      res[key] = ua[key];
    }
  }
  res['inner_width'] = String(window.innerWidth);
  res['inner_height'] = String(window.innerHeight);
  return cleanDeep(res);
}
