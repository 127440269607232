const URLHelper = {
  getPathName(url: string) {
    try {
      return url && new URL(url).pathname;
    } catch {
      return null;
    }
  },
  getSearch(url: string) {
    try {
      return url && new URL(url).search;
    } catch {
      return null;
    }
  },
  getHost(url: string) {
    try {
      return url && new URL(url).host;
    } catch {
      return null;
    }
  },
};

export default URLHelper;
