const TimeStamp = {
  get() {
    return new Date().getTime();
  },
  format(timestamp: number) {
    return new Date(timestamp).toISOString();
  },
};

export default TimeStamp;
