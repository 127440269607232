import React, { useMemo } from 'react';

import { Link } from 'gatsby';

import { ReactComponent as IconPhone } from '../../../assets/icon-phone-orange.svg';
import { ReactComponent as ArrowBottom } from '../../../assets/icon-arrow-bottom.svg';
import { CARD_LINKS, LINKS } from '../constants';
import TopPanelLogged from '../../UniversalTopPanelRender/TopPanelLogged';
import { MenuProps } from '../types';

import useDropdownMenu from './useDropdownMenu';
import { createPairArray } from './utils';

import './LoggedUser.scss';

interface DropdownMobileProps extends MenuProps {
  toggleDropdown: () => void;
};

const DropdownMobile = (props: DropdownMobileProps) => {
  const { loading, onClickCTA, phoneNumber, phoneLink, signInPath, user, onClickPath, toggleDropdown } = props;
  const { isOpen, toggleIsOpen, ref } = useDropdownMenu();
  const { isOpen: isUserOpen, toggleIsOpen: toggleUserOpen } = useDropdownMenu();
  const pairedLinks = useMemo(() => {
    return createPairArray(CARD_LINKS);
  }, [CARD_LINKS]);
  return (
    <>
      <div ref={ref} className="self-stretch flex-1 flex flex-col items-start justify-start pt-0 px-0 pb-4 gap-[5px]">
        <div className="self-stretch bg-base-white-100 flex flex-col items-start justify-center pt-1.5 pr-3 pl-5 gap-[10px]">
          <div className="self-stretch rounded-roundings-xs h-9 flex flex-row items-center justify-between cursor-pointer" onClick={toggleIsOpen}>
            <div className="flex flex-row items-center justify-start">
              <div className="relative leading-[24px]">Fences</div>
            </div>
            <div className="flex flex-row items-start justify-start">
              <ArrowBottom className={`relative transition-transform duration-300 ease-out ${isOpen ? 'rotate-[90deg]' : ''}`} />
            </div>
          </div>
          {isOpen && (
            <div className="self-stretch flex flex-col items-start justify-center py-0 pr-2 pl-0 font-button-big">
              <div className="self-stretch h-[125px] flex flex-row items-start justify-start gap-[12px]">
                {pairedLinks.map((pair, rowIndex) => (
                  <div key={rowIndex} className="self-stretch flex-1 flex flex-col items-start justify-start gap-[12px]">
                    {pair.map((link, linkIndex) => (
                      <Link key={linkIndex} to={link.path} onClick={toggleDropdown} className="self-stretch flex-1 rounded-roundings-xs bg-gray-6 hover:bg-blue-3 overflow-hidden flex flex-row items-center justify-between py-1 pr-2.5 pl-5 text-black-1 hover:text-black-1">
                        <div className="relative leading-[20px] font-medium">{link.label}</div>
                        <ArrowBottom className="relative rotate-[-90deg]" />
                      </Link>
                    ))}
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
        {LINKS.map((link, index) => (
          <Link key={index} to={link.path} onClick={toggleDropdown} className="w-full h-12 flex flex-col items-start justify-center py-0 px-5 box-border font-medium text-black-1 hover:text-black-1">
            <div className="self-stretch rounded-roundings-xs h-9 flex flex-row items-center justify-between">
              <div className="flex flex-row items-center justify-start">
                <div className="relative leading-[24px]">{link.label}</div>
              </div>
              <div className="w-6 h-6" />
            </div>
          </Link>
        ))}
        {!user ? (
          <Link to={signInPath} className="self-stretch h-12 flex flex-col items-start justify-center py-0 px-5 box-border font-medium text-black-1 hover:text-black-1">
            <div className="self-stretch rounded-roundings-xs h-9 flex flex-row items-center justify-between">
              <div className="flex flex-row items-center justify-start">
                <div className="relative leading-[24px]">Sign In</div>
              </div>
              <div className="w-6 h-6" />
            </div>
          </Link>
        ) : (
          <>
            <div className="self-stretch h-12 flex flex-col items-start justify-center pt-1.5 pr-3 pl-5 box-border font-medium text-black-1 hover:text-black-1 cursor-pointer" onClick={toggleUserOpen}>
              <div className="self-stretch rounded-roundings-xs h-9 flex flex-row items-center justify-between">
                <div className="flex flex-row items-center justify-start gap-[10px]">
                  <div className="relative w-6 h-6 overflow-hidden rounded-full">
                    <svg className="absolute icon" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fillRule="evenodd" clipRule="evenodd" d="M16 8A8 8 0 100 8a8 8 0 0016 0zM1.333 8A6.667 6.667 0 1113.2 12.172v-.198c0-1.558-1.17-2.856-2.727-3.174A4.501 4.501 0 018 9.535c-.92 0-1.77-.275-2.473-.735C3.97 9.118 2.8 10.416 2.8 11.974v.198A6.638 6.638 0 011.333 8zM8 8.8a2.8 2.8 0 100-5.6 2.8 2.8 0 000 5.6z" fill="#BEBDC4" />
                    </svg>
                  </div>
                  <div className="relative leading-[24px]">{user.full_name}</div>
                </div>
                <div className="flex flex-row items-start justify-start">
                  <ArrowBottom className={`relative transition-transform duration-300 ease-out ${isUserOpen ? 'rotate-[90deg]' : ''}`} />
                </div>
              </div>
            </div>
            {isUserOpen && (
              <div className="LoggedUserMenu w-full flex flex-col items-start pl-3 box-border font-medium text-black-1 hover:text-black-1 gap-[5px]">
                <TopPanelLogged user={user} onClickPath={onClickPath} />
              </div>
            )}
          </>
        )}
      </div>
      <div className="w-full flex flex-col py-6 px-5 gap-[16px] text-base-white-100 font-button-big">
        <button className="self-stretch rounded-roundings-xs bg-orange-1 h-12 py-3 px-5 box-border text-center font-button-medium text-sm text-base-white-100 cursor-pointer" onClick={onClickCTA}>
          <div className="relative leading-[24px] font-semibold">
            {loading ? (
              <svg aria-hidden="true" className="w-4 h-4 text-gray-4 animate-spin fill-blue-1" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
              </svg>
            ) : 'Get a Quote'}
          </div>
        </button>
        <a href={`tel:${phoneLink}`} className="rounded-roundings-xs h-12 flex self-center items-center py-3 px-5 box-border gap-[6px] text-black-1 text-center">
          <IconPhone className="w-5 relative h-5" />
          <div className="relative leading-[24px] font-semibold">{phoneNumber}</div>
        </a>
      </div>
    </>
  );
};

export default DropdownMobile;
