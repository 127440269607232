import slugify from "slugify";

import {getSessionData} from "./utils/UTMData";

let setTawk;
export const tawkAPILoader = new Promise(resolve => setTawk = resolve);

export const trackTawkEvent = (eventName, data) => {
  tawkAPILoader.then((TawkAPI = {}) => {
    if (TawkAPI && TawkAPI.addEvent) {
      TawkAPI.addEvent(slugify(eventName, {lower: true}), data);
    } else {
      console.error("TawkAPI is not defined");
    }
  });
};

const onTawkScriptLoad = () => {
  const TawkAPI = window.Tawk_API || {};
  if (TawkAPI) {
    setTawk(TawkAPI);
    getSessionData().then(sessionData => {
      // NOTE: `sessionData` undefined in WebView, e.g. in Facebook browser
      const initialReferrer = sessionData ? sessionData["document"]["referrer"] : null;
      TawkAPI.addTags([`Initial referrer: ${initialReferrer}`], () => null);
      trackTawkEvent("UTM Data", {
        source: sessionData ? sessionData["utm"]["utm_source"] : null,
        "initial-referrer": initialReferrer,
      });
    });
  } else {
    console.error("window.Tawk_API not defined");
  }
};

export const initTawk = (apiKey, property = "default") => {
  // eslint-disable-next-line camelcase
  window.Tawk_API = window.Tawk_API || {};
  window.Tawk_API.onLoad = onTawkScriptLoad;

  const s1=document.createElement("script"), s0=document.getElementsByTagName("script")[0];
  s1.async=true;
  s1.src=`https://embed.tawk.to/${apiKey}/${property}`;
  s1.charset="UTF-8";
  s1.setAttribute("crossorigin", "*");
  s0.parentNode.insertBefore(s1, s0);
};
