import { useCallback, useEffect, useState } from 'react';

import { graphql, useStaticQuery } from 'gatsby';

const VIEWS = {
  isMobile: false,
  isTablet: false,
  isDesktop: false,
};

const MOBILE_SIZE = 768;
const TABLET_SIZE = 991;

export const useIsMobileView = () => {
  const [screenView, setScreenView] = useState(VIEWS);

  const onResize = useCallback(() => {
    if (window.innerWidth <= MOBILE_SIZE) {
      setScreenView({ ...VIEWS, isMobile: true });
    } else if (window.innerWidth > MOBILE_SIZE && window.innerWidth <= TABLET_SIZE) {
      setScreenView({ ...VIEWS, isTablet: true });
    } else {
      setScreenView({ ...VIEWS, isDesktop: true });
    }
  }, []);

  useEffect(function resizeOnMount() {
    onResize();
  }, []);

  useEffect(function watchResize() {
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [onResize]);

  return screenView;
};

export const useScrollPosition = () => {
  const [scrollPosition, setScrollPosition] = useState(0);

  const updatePosition = () => {
    setScrollPosition(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener('scroll', updatePosition);

    return () => window.removeEventListener('scroll', updatePosition);
  }, []);

  return scrollPosition;
};

export const useSiteMetadata = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
          image
          siteUrl
        }
      }
    }
  `);

  return data.site.siteMetadata;
};
