import React, { useMemo } from 'react';

import { Link } from 'gatsby';

import { CARD_LINKS } from '../constants';

import FenceCalculatorCard from './FenceCalculatorCard';
import { createPairArray } from './utils';

const MenuDesktop = () => {
  const pairedLinks = useMemo(() => {
    return createPairArray(CARD_LINKS);
  }, [CARD_LINKS]);

  return (
    <div className="rounded-2xl bg-base-white-100 w-full flex flex-row items-center justify-start p-4 box-border gap-[0px_24px] text-left text-base text-black-1 font-button-small drop-shadow-lg">
      <div className="flex flex-row items-start justify-start">
        <div className="flex flex-col items-start justify-start gap-[12px]">
          {pairedLinks.map((pair, rowIndex) => (
            <div key={rowIndex} className="flex flex-row items-start justify-start gap-[0px_12px]">
              {pair.map((link, linkIndex) => (
                <div key={linkIndex} className="w-[220px] flex flex-col items-start justify-start gap-[12px]">
                  <Link to={link.path} className="w-[220px] rounded-roundings-xs bg-gray-6 hover:bg-blue-3 text-black-1 hover:text-black-1 h-[90px] overflow-hidden shrink-0 flex flex-row items-center justify-between py-1 pr-0 pl-5 box-border">
                    <div className="relative leading-[20px] font-medium">{link.label}</div>
                    <img className="w-[120px] h-[90px] object-cover mix-blend-multiply" alt="" src={link.image} />
                  </Link>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
      <FenceCalculatorCard />
    </div >
  );
};

export default MenuDesktop;
