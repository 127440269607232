import React from 'react';

import { Link } from 'gatsby';

import { ReactComponent as Logo } from '../../assets/top-panel-logo.svg';
import { ReactComponent as IconPhone } from '../../assets/icon-phone-orange.svg';
import { ReactComponent as IconBurger } from '../../assets/icon-burger.svg';
import { ReactComponent as IconClose } from '../../assets/icon-close.svg';
import { CLICK_TO_CALL } from '../../utils/events';

import DropdownMobile from './Dropdown/DropdownMobile';
import useDropdownMenu from './Dropdown/useDropdownMenu';
import { MenuProps } from './types';

interface TopPanelMobileViewProps extends MenuProps { };

const TopPanelMobileView = (props: TopPanelMobileViewProps) => {
  const { ergeonUrl, loading, onClickCTA, phoneNumber, phoneLink, signInPath, user, onClickPath } = props;
  const { isOpen, toggleIsOpen, ref } = useDropdownMenu();

  return (
    <div className="w-full absolute !m-[0] top-[0px] left-[0px] overflow-hidden flex flex-col items-center justify-center text-left text-base text-black-1 font-big-text-regular">
      <div className={`self-stretch flex flex-col items-center justify-start gap-[6px] bg-base-white-100 shadow-[0px_3px_70px_rgba(0,_86,_165,_0.1)] ${isOpen ? 'rounded-b-xl mb-9 drop-shadow-lg' : ''}`}>
        <div className="self-stretch flex flex-row items-center justify-between p-1.5">
          <button className="rounded-roundings-xs h-9 flex flex-row items-center justify-center p-2.5 box-border bg-base-white-100 border-b-white cursor-pointer" onClick={toggleIsOpen}>
            {isOpen ? <IconClose className="w-[18px] h-[18px]" /> : <IconBurger className="w-[18px] h-[18px]" />}
          </button>
          <Link to={ergeonUrl as string} className="relative object-cover flex items-center">
            <Logo />
          </Link>
          <a data-tracking-id={CLICK_TO_CALL} href={`tel:${phoneNumber}`} className="rounded-roundings-xs h-9 flex flex-row items-center justify-center box-border">
            <IconPhone className="w-5 relative h-5" />
          </a>
        </div>
        {isOpen && (
          <div ref={ref} className="self-stretch">
            <DropdownMobile
              loading={loading}
              onClickCTA={onClickCTA}
              phoneNumber={phoneNumber}
              phoneLink={phoneLink}
              signInPath={signInPath}
              user={user}
              onClickPath={onClickPath}
              toggleDropdown={toggleIsOpen}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default TopPanelMobileView;
