import React, { useCallback, useEffect, useMemo } from 'react';

import { navigate } from 'gatsby';
import classNames from 'classnames';
import NavLinkContext from '@ergeon/core-components/exports/NavLinkContext';
import first from 'lodash/first';

import AppFooter from '../components/AppFooter';
import {
  isChristmasTime as getIsChristmasTime,
  getLocationList,
} from '../utils/utils';
import { useScrollPosition } from '../utils/hooks';
import { trackBrowserParams } from '../utils/analytics/trackBrowserParams';
import ShowUpcomingFeaturesComponent from '../utils/showUpcomingFaturesComponent';
import NewTopPanel from '../components/NewTopPanel';

import {
  useInitGoogleLoader,
  useLocationCheck,
  useMainInit,
} from './customHooks';
import { GeoTargetRoutingInterceptor } from './RoutingInterceptor';
import { LayoutProps } from './Layout';
import CustomLink from './components/CustomLink';

import './index.scss';

const Layout = (props: LayoutProps) => {
  const { children, data, location } = props;

  const scrollPosition = useScrollPosition();

  const isChristmasTime = useMemo(() => {
    return getIsChristmasTime();
  }, []);

  const { isNarrowTemplate, isNoFooterTemplate, isProPage, isHomePage } =
    useLocationCheck(location);

  const { nodes: cities } = data?.allSanityCityData || {};
  const city = isHomePage ? undefined : first(cities);

  const locationsList = useMemo(() => {
    const transformedCities = cities?.map((city) => ({
      city: city.city,
      slug: city.slug.current,
    }));
    return getLocationList(transformedCities || []);
  }, [cities, getLocationList]);

  const onGetQuoteClick = useCallback(() => {
    return navigate('/request-quote/');
  }, [navigate]);

  const onClickProCTA = useCallback(() => {
    return navigate('#pro-lead-form');
  }, []);

  const onCTAClick = useMemo(() => {
    return isProPage ? onClickProCTA : onGetQuoteClick;
  }, [isProPage, onClickProCTA, onGetQuoteClick]);

  const shouldAppHeaderShadow = useMemo(() => {
    const isRegulationPage = location?.pathname.includes('/regulations');
    const isScrolling = scrollPosition !== 0;
    return isRegulationPage || isScrolling;
  }, [location, scrollPosition]);

  const headerWrapperClass = useMemo(() => {
    return classNames({
      'app-header-shadow': shouldAppHeaderShadow,
    });
  }, [shouldAppHeaderShadow]);

  const widthClass = useMemo(() => {
    return classNames({
      'wrapper-980': isNarrowTemplate,
      'wrapper-1180': !isNarrowTemplate,
    });
  }, [isNarrowTemplate]);

  const showFooter = useMemo(() => {
    return !isNoFooterTemplate;
  }, [isNoFooterTemplate]);

  const renderUTMIframe = useMemo(() => {
    return <div id="utm-iframe" style={{ display: 'none' }}></div>;
  }, []);

  useEffect(function init() {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useMainInit();
  }, []);

  useEffect(
    function trackVisitorBrowserParams() {
      trackBrowserParams();
    },
    [location],
  );

  useInitGoogleLoader();

  return (
    <div id="root" data-scroll-container>
      {renderUTMIframe}
      <div className="app-layout">
        <ShowUpcomingFeaturesComponent />
        <NavLinkContext.Provider value={CustomLink}>
          <header
            className={classNames('app-header', {
              'no-margin': isHomePage,
            })}
          >
            <div
              className={`app-header-wrapper app-header-static ${headerWrapperClass}`}
            >
              <NewTopPanel
                ergeonUrl="/"
                fenceQuotingUrl={`${process.env.FENCEQUOTING_HOST}/`}
                showChristmasHat={isChristmasTime}
                isProPage={isProPage}
                onCTAClick={onCTAClick}
              />
            </div>
          </header>
          <main
            className={classNames({
              'app-main': isHomePage,
            })}
          >
            <GeoTargetRoutingInterceptor>
              {children}
            </GeoTargetRoutingInterceptor>
          </main>
          {showFooter && (
            <AppFooter
              city={city}
              ergeonUrl="/"
              fencequotingUrl={`${process.env.FENCEQUOTING_HOST}/`}
              locationsList={locationsList}
              widthClass={widthClass}
            />
          )}
        </NavLinkContext.Provider>
      </div>
    </div>
  );
};

export default Layout;
