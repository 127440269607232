/* eslint-disable import/no-unresolved */
import React from 'react';

import { ReactComponent as IconAppointments } from '@ergeon/core-components/src/assets/icon-appointments.svg';
import { ReactComponent as IconLogoutSolid } from '@ergeon/core-components/src/assets/icon-logout-solid.svg';
import { ReactComponent as IconMenuOrders } from '@ergeon/core-components/src/assets/icon-menu-orders.svg';
import { ReactComponent as IconQuestionMark } from '@ergeon/core-components/src/assets/icon-question-mark.svg';

import { MenuItems } from '../../types';
import { HELP_PATH, LOGOUT_PATH } from '../../../utils/constants';

import useUserDetailsItem, { useUserDetailsItemProps } from './useUserDetailsItem';
import useRegularMenuItems from './useRegularMenuItems';

interface useMenuItemsProps extends useUserDetailsItemProps { }

export default function useMenuItems(props: useMenuItemsProps) {
  const { onClickPath, profileName } = props;

  const orderItem = useRegularMenuItems({ label: MenuItems.ORDERS, onClickPath, icon: <IconMenuOrders /> });
  const appointmentItem = useRegularMenuItems({
    label: MenuItems.APPOINTMENTS,
    onClickPath,
    icon: <IconAppointments />,
  });
  const userDetailsItem = useUserDetailsItem({ onClickPath, profileName });
  const helpItem = useRegularMenuItems({
    label: MenuItems.HELP,
    onClickPath,
    icon: <IconQuestionMark />,
    asPath: true,
    path: HELP_PATH,
  });
  const logOutItem = useRegularMenuItems({
    label: MenuItems.LOGOUT,
    onClickPath,
    icon: <IconLogoutSolid />,
    asPath: true,
    path: LOGOUT_PATH,
  });

  return [orderItem, appointmentItem, userDetailsItem, helpItem, logOutItem];
}
