import React from 'react';

import { Link } from 'gatsby';

import { ReactComponent as Logo } from '../../assets/top-panel-logo.svg';
import { ReactComponent as IconPhone } from '../../assets/icon-phone-orange.svg';
import { ReactComponent as IconBurger } from '../../assets/icon-burger.svg';
import { ReactComponent as IconClose } from '../../assets/icon-close.svg';
import { CLICK_TO_CALL } from '../../utils/events';

import DropdownTablet from './Dropdown/DropdownTablet';
import useDropdownMenu from './Dropdown/useDropdownMenu';
import CTAButton from './CTAButton';
import UserLink from './UserLink';
import { MenuProps } from './types';

interface TopPanelTabletViewProps extends MenuProps { };

const TopPanelTabletView = (props: TopPanelTabletViewProps) => {
  const { ergeonUrl, phoneNumber, phoneLink, onClickCTA, loading, user, signInPath, onClickPath } = props;
  const { isOpen, toggleIsOpen, ref } = useDropdownMenu();
  return (
    <div className="w-full relative text-left text-sm text-black-1 font-button-medium">
      <div className="absolute w-full top-[0px] left-[0px] bg-base-white-100 shadow-[0px_3px_70px_rgba(0,_86,_165,_0.1)] h-16 flex flex-col items-center justify-center">
        <div className="max-w-[90%] w-full mx-auto flex flex-row items-center justify-between">
          <Link to={ergeonUrl as string} className="w-[180px] relative h-10 object-cover flex items-center scale-110">
            <Logo />
          </Link>
          <div className="flex flex-row items-start justify-start gap-[10px]">
            <a data-tracking-id={CLICK_TO_CALL} href={`tel:${phoneLink}`} className="rounded-roundings-xs bg-base-white-100 h-9 flex flex-row items-center justify-center py-2 px-4 box-border gap-[6px] text-black-1">
              <IconPhone className="w-[18px] relative h-[18px]" />
              <div className="relative leading-[20px] font-semibold">{phoneNumber}</div>
            </a>
            <CTAButton loading={loading} onClickCTA={onClickCTA} />
            <UserLink user={user} signInPath={signInPath} onClickPath={onClickPath} />
            <div className="relative inline-block">
              <button className="rounded-roundings-xs h-9 flex items-center justify-center p-2.5 box-border bg-base-white-100 border-b-white cursor-pointer" onClick={toggleIsOpen}>
                {isOpen ? <IconClose className="w-[18px] h-[18px]" /> : <IconBurger className="w-[18px] h-[18px]" />}
              </button>
              <div ref={ref} className={`absolute transition-all duration-300 ease-out origin-top ${isOpen ? 'opacity-100 scale-100 visible' : 'opacity-0 scale-95 invisible'}`}>
                <DropdownTablet />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >
  );
};

export default TopPanelTabletView;
