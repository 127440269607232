/* eslint-disable import/no-unresolved */
import React, { useCallback, useMemo } from 'react';

import { useLocation } from '@reach/router';
import { ReactComponent as IconAddress } from '@ergeon/core-components/src/assets/icon-address.svg';
import { ReactComponent as IconContacts } from '@ergeon/core-components/src/assets/icon-contacts.svg';
import { ReactComponent as IconPayments } from '@ergeon/core-components/src/assets/icon-payments.svg';
import { ReactComponent as IconPreference } from '@ergeon/core-components/src/assets/icon-preference.svg';

import { MenuItems } from '../../types';

export interface useUserDetailsItemProps {
  onClickPath?: (path: string) => void;
  profileName?: string;
}

const useUserDetailsItem = (props: useUserDetailsItemProps) => {
  const { onClickPath, profileName } = props;
  const location = useLocation();

  const setActiveClassName = useCallback((path: string) => {
    if (location.pathname.includes(path.toLocaleLowerCase())) {
      return 'active-menu-item';
    }
    return '';
  }, [location]);

  const label = useMemo(() => profileName, [profileName]);
  const buttons = [
    {
      icon: <IconContacts />,
      label: MenuItems.PROFILE,
      className: setActiveClassName(MenuItems.PROFILE),
      onClick() {
        onClickPath?.(MenuItems.PROFILE);
      },
    },
    {
      icon: <IconAddress />,
      label: MenuItems.ADDRESSES,
      className: setActiveClassName(MenuItems.ADDRESSES),
      onClick() {
        onClickPath?.(MenuItems.ADDRESSES);
      },
    },
    {
      icon: <IconPayments />,
      label: MenuItems.PAYMENTS,
      className: setActiveClassName(MenuItems.PAYMENTS),
      onClick() {
        onClickPath?.(MenuItems.PAYMENTS);
      },
    },
    {
      icon: <IconPreference />,
      label: MenuItems.SETTINGS,
      className: setActiveClassName(MenuItems.SETTINGS),
      onClick() {
        onClickPath?.(MenuItems.SETTINGS);
      },
    },
  ];

  return {
    label,
    buttons,
  };
};

export default useUserDetailsItem;
