export const ERGEON_DOMAIN = 'ergeon.com';

export const SESSION_INITIAL_KEY = "_initial";

export const VISITOR_ID_KEY = 'visitorId';

export const NO_REFERRER = 'No referrer';

export const PARAMS_TIME_STAMP_KEY = 'initialTs';

export const DAY_PERIOD = 24 * 60 * 60 * 1000; // in milliseconds
export const RESTORE_SESSION_PERIOD = DAY_PERIOD;
export const REFERRAL_CODE_VALID_PERIOD = 60 * 24 * 60 * 60 * 1000; // 6O days in milliseconds

export const REFERRAL_TIMESTAMP_KEY = 'referralTs';
export const REFERRAL_KEY = 'referral';
export const REFERRAL_CODE_KEY = 'referralCode';

export const USER_IP_KEY = 'user_ip_info';
export const IP_LOOKUP_URL = 'https://api.ergeon.in/api/geo/get-location-from-ip/';
export const IP_LOOKUP_URL_STAGING = 'https://apidev.ergeon.in/api/geo/get-location-from-ip/';
