import TimeStamp from './TimeStamp';
import {UTMs} from './UTMParser';
import {PARAMS_TIME_STAMP_KEY, REFERRAL_TIMESTAMP_KEY, REFERRAL_CODE_VALID_PERIOD, REFERRAL_KEY} from '../constants';


const DEFAULT_EXPIRATION_PERIOD: number | null = null; // if null - don't expire

/**
 * Returns true when run in Headless Chrome
 * @returns true/false
 */
export function isHeadlessBrowser(): boolean {
  if (global?.navigator?.userAgent) {
    const {userAgent} = global.navigator;
    return userAgent.toString().toLowerCase().includes('headlesschrome');
  }
  return false;
}

export function isDataExpired(data: UTMs) {
  if (!data || !data[PARAMS_TIME_STAMP_KEY]) {
    return true;
  }
  if (!DEFAULT_EXPIRATION_PERIOD) {
    return false;
  }
  return (
    (parseInt(data[PARAMS_TIME_STAMP_KEY] as string, 10) + DEFAULT_EXPIRATION_PERIOD) < TimeStamp.get()
  );
}

/**
 * Rejects if specified promise doesn’t resolve in provided `timeout` ms.
 */
 export const promiseTimeout = function<T>(
   promise: Promise<T>,
   timeout: number,
   funcName = 'unknown',
  ) {
  const timeoutPromise = new Promise((resolve, reject) => {
    const timeoutID = setTimeout(() => {
      clearTimeout(timeoutID);
      reject(`Function ${funcName} took more than ${timeout} ms to execute, rejecting`);
    }, timeout);
  });
  return Promise.race([promise, timeoutPromise]);
};

/**
 * Rejects if specified promise doesn’t resolve after provided `times` re-runs
 * (each re-run should be resolved in provided `timeout` ms).
 */
export const promiseRerunOnReject = function<T>(
  promise: Promise<T>,
  timeout: number,
  times: number,
  funcName = 'unknown',
) {
  let count = 0;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const rerunOnReject = (resolve: (success: any) => void, reject: (error: string) => void) => {
    promiseTimeout(promise, timeout, funcName)
      .then(resolve)
      .catch(error => {
        count++;
        if (count === times) {
          reject(`${count} re-runs for ${funcName} failed. Original error: ${error}`);
        } else {
          rerunOnReject(resolve, reject);
        }
      });
  };
  return new Promise(rerunOnReject);
};

/**
 * Returns if referral code is valid. Referral Code Valid for 60 days.
 */
export function isReferralExpired(data: UTMs): boolean{
  const currentTs = TimeStamp.get();
  if (data) {
    const {
      [REFERRAL_KEY]: initialReferralData,
    } = data ?? {};
    const referralTs = initialReferralData?.[REFERRAL_TIMESTAMP_KEY];
    if (referralTs) {
      return currentTs - referralTs > REFERRAL_CODE_VALID_PERIOD;
    }
  }
  return false;
}

// lite api to make non-authenticated request
export function RequestAPI(url: string) {
  return new Promise((resolve, reject) => {
    fetch(url)
      .then((response) => response.json())
      .then(resolve)
      .catch(reject);
  });
}
