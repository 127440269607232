import * as Sentry from '@sentry/gatsby';
import type { BrowserOptions } from '@sentry/browser';
import getExtendedOptions from '@ergeon/erg-utils-js/exports/sentry/getExtendedOptions';

const IS_DEVELOPMENT = process.env.NODE_ENV === 'development';

const isBrowser = typeof window !== 'undefined';

const SentryInit = (options: BrowserOptions) => {
  Sentry.init(getExtendedOptions(options));
}

// We should also skip Sentry on SSR builds
if (!IS_DEVELOPMENT && isBrowser) {
  SentryInit({
    dsn: process.env.SENTRY_DSN,
    environment: process.env.NODE_ENV,
    release: process.env.SENTRY_RELEASE_NAME,
    integrations: [
      new Sentry.BrowserTracing(),
    ],
    tracesSampleRate: 0.2,
    ignoreErrors: [/page resources for .* not found\\. Not rendering React/]
  });
} else {
  console.warn('Sentry is disabled in development/SSR build mode, Ignore the error below');
}
