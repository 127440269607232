/* eslint-disable no-console */
import { listenClickToCall } from './analytics/trackClickToCall';
import { OFFLINE_FORM_SUBMIT, CHAT_STARTED, CHAT_WIDGET } from './events';
import { isPDFMode } from './utils';

declare global {
  interface Window {
    Tawk_API: {
      onOfflineSubmit: (data: unknown) => void;
      onChatStarted: (data: unknown) => void;
      onChatMessageVisitor: () => void;
    };
  }
}

export default function() {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const { init } = require('./analytics/init');
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const { track } = require('./analytics/track');
  const pdfModeDisabled = !isPDFMode();

  if (pdfModeDisabled) {
    // <!--Start of Tawk.to Script-->
    // @ts-ignore eslint-disable-next-line import/no-unresolved
    import('@ergeon/erg-utms').then((args) => {

      const tawk = args.tawk;

      window.Tawk_API = window.Tawk_API || {};

      tawk.initTawk(process.env.TAWK_API_KEY);

      window.Tawk_API.onOfflineSubmit = function(data) {
        track(OFFLINE_FORM_SUBMIT, data);
        track(CHAT_WIDGET, { liveChat: 'FALSE' });
      };

      window.Tawk_API.onChatStarted = function(data) {
        track(CHAT_STARTED, data);
      };

      window.Tawk_API.onChatMessageVisitor = function() {
        track(CHAT_WIDGET, { liveChat: 'TRUE' });
      };

    }).catch(() => {
      console.error('An error occurred while loading tawk script');
    });
    // <!--End of Tawk.to Script-->
  }

  // init link analytics tracking
  init();
  listenClickToCall();

  let counter = 0;

  document.addEventListener('DOMContentLoaded', function() {
    const hash = document.location.hash;
    if (!hash.length) return;
    const imageSlug = hash.slice(1);
    if (imageSlug && counter === 0) {
      counter++;
      const imageLink =
        document.querySelector(`div[gallery-link-id='${imageSlug}']`) as HTMLAnchorElement | HTMLButtonElement;
      if (imageLink) {
        imageLink.click();
      }
    }
  });
}
