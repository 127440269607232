import React, { useEffect, useState, useCallback, useMemo } from 'react';

import { navigate } from 'gatsby';
// eslint-disable-next-line import/no-unresolved
import { useLocation } from '@reach/router';

import { ERGEON1_PHONE, HELP_PATH } from '../../utils/constants';
import { checkRouteList } from '../../utils/utils';
import { getAuthService } from '../../utils/auth';
import { AuthUser } from '../types';
import UniversalTopPanelRender from '../UniversalTopPanelRender/UniversalTopPanelRender';
import LocaleToggle from '../../i18n/LocaleToggle';

import TopPanelDesktopView from './DesktopView';
import TopPanelTabletView from './TabletView';
import TopPanelMobileView from './MobileView';

const DEFAULT_PHONE = '1-888-374-3661';
const SIGN_IN_PATH = '/app/sign-in';

interface NewTopPanelRenderProps {
  ergeonUrl?: string;
  fenceQuotingUrl?: string;
  isProPage?: boolean;
  phoneNumber?: string;
  showChristmasHat?: boolean;
  onCTAClick?: () => void;
}

const NewTopPanel = (props: NewTopPanelRenderProps) => {
  const {
    ergeonUrl = process.env.HOME_PAGE as string,
    isProPage,
    phoneNumber = ERGEON1_PHONE,
    showChristmasHat,
    onCTAClick,
  } = props;
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState<AuthUser | null>(null);

  const isRequestQuotePage = useMemo(() => {
    return checkRouteList(['/request-quote'], location);
  }, [location]);

  const onClickPath = useCallback((path: string, asPath = false) => {
    path = path.toLocaleLowerCase().replaceAll(' ', '');
    const url = !asPath ? `${user?.gid}/${path}` : `${path}`;

    if (url.includes(HELP_PATH)) {
      return navigate('/help');
    }
    return window.location.href = `${process.env.CUSTOMER_APP}/app/${url}/`;
  }, [user]);

  const onClickCTA = useCallback(() => {
    if (isRequestQuotePage) {
      setLoading(false);
      return;
    }
    setLoading(true);
    onCTAClick?.();
  }, [isRequestQuotePage, onCTAClick]);

  useEffect(function watchSpinner() {
    if (isRequestQuotePage) {
      setLoading(false);
    }
  }, [loading, isRequestQuotePage]);

  useEffect(function getLoggedUser() {
    (async() => {
      try {
        const authService = getAuthService();
        const user = await authService.getUser();
        setUser(user);
      } catch (userError) {
        console.error('userError', userError);
      }
    })();
  }, []);

  if (isProPage) {
    return (
      <UniversalTopPanelRender
        ergeonUrl="/"
        fenceQuotingUrl={`${process.env.FENCEQUOTING_HOST}/`}
        showChristmasHat={showChristmasHat}
        isProPage={isProPage}
        languageToggle={<LocaleToggle />}
        onCTAClick={onCTAClick}
        isHomePage={false}
      />
    );
  }

  return (
    <>
      <div className="hidden lg:block">
        <TopPanelDesktopView
          ergeonUrl={ergeonUrl}
          phoneNumber={DEFAULT_PHONE}
          phoneLink={phoneNumber}
          onClickCTA={onClickCTA}
          onClickPath={onClickPath}
          loading={loading}
          user={user}
          signInPath={SIGN_IN_PATH}
        />
      </div>
      <div className="hidden sm:hidden md:block lg:hidden">
        <TopPanelTabletView
          ergeonUrl={ergeonUrl}
          phoneNumber={DEFAULT_PHONE}
          phoneLink={phoneNumber}
          onClickCTA={onClickCTA}
          onClickPath={onClickPath}
          loading={loading}
          user={user}
          signInPath={SIGN_IN_PATH}
        />
      </div>
      <div className="block md:hidden">
        <TopPanelMobileView
          ergeonUrl={ergeonUrl}
          phoneNumber={DEFAULT_PHONE}
          phoneLink={phoneNumber}
          onClickCTA={onClickCTA}
          onClickPath={onClickPath}
          loading={loading}
          user={user}
          signInPath={SIGN_IN_PATH}
        />
      </div>
    </>
  );
};

export default NewTopPanel;
