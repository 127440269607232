import Postmate from 'postmate';

import { UTMs } from './UTMParser';

const outputHandshakes: Record<string, Postmate> = {};
let inputHandshake: Postmate.Model = null;

type DomainDataSendToParams = {
  containerID: string,
  senderName: string,
  targetURL: string,
  data: UTMs,
};

const DomainData = {
  async sendTo({containerID, senderName, targetURL, data}: DomainDataSendToParams) {
    if (outputHandshakes[targetURL] !== undefined) {
      return;
    }
    const handshake = new Postmate({
      container: document.getElementById(containerID),
      url: targetURL,
    });
    outputHandshakes[targetURL] = handshake;
    const child = await handshake;
    child.call('utmHandler', {senderName, data});
  },
  receiveFrom(utmHandler: (receivedData: UTMs) => void) {
    if (!inputHandshake) {
      inputHandshake = new Postmate.Model({ utmHandler });
    }
    return inputHandshake;
  }
};

export default DomainData;
