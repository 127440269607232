import React from 'react';

import { Link } from 'gatsby';

import TopPanelLogged from '../UniversalTopPanelRender/TopPanelLogged';

import { MenuProps } from './types';

interface UserLinkProps extends Omit<MenuProps, 'loading' | 'onClickCTA' | 'phoneNumber' | 'phoneLink'> { };

const UserLink = ({ user, signInPath, onClickPath }: UserLinkProps) => {
  return (
    !user ? (
      <Link to={signInPath} className="rounded-roundings-xs bg-base-white-100 h-9 flex flex-row items-center justify-center py-2 px-4 box-border text-black-1">
        <div className="relative leading-[20px] font-semibold">Sign in</div>
      </Link>
    ) : (
      <TopPanelLogged user={user} onClickPath={onClickPath} />
    )
  );
};

export default UserLink;
