import { VISITOR_CLICK } from '../events';

import { track } from './track';

export const trackVisitorClick = (clickText: string | null, clickAction: string): void => {
  if (!window) return;

  track(VISITOR_CLICK, {
    visitor_click_text: clickText,
    visitor_click_action: clickAction,
  });
};
