import React, { useMemo } from 'react';

import { Link } from 'gatsby';

import { LINKS, CARD_LINKS } from '../constants';
import { ReactComponent as ArrowBottom } from '../../../assets/icon-arrow-bottom.svg';

import { FenceCalculatorCardTablet } from './FenceCalculatorCard';
import useDropdownMenu from './useDropdownMenu';
import { createPairArray } from './utils';

const DropdownTablet = () => {
  const { isOpen, toggleIsOpen, ref } = useDropdownMenu();
  const pairedLinks = useMemo(() => {
    return createPairArray(CARD_LINKS);
  }, [CARD_LINKS]);
  return (
    <div ref={ref} className="absolute top-[20px] left-[-350px] rounded-roundings-l bg-base-white-100 flex flex-col items-start justify-start pt-3 px-3 pb-4 gap-[5px] text-base font-big-text-regular drop-shadow-lg">
      <div className="w-full relative flex flex-col items-start justify-center pt-0 pb-3 pr-3 pl-5 box-border gap-[10px] text-left text-base text-black-1 font-medium-text-regular">
        <div className="self-stretch rounded-roundings-xs h-9 flex flex-row items-center justify-between cursor-pointer transition-all duration-300" onClick={toggleIsOpen}>
          <div className="flex flex-row items-center justify-start">
            <div className="relative leading-[24px]">Fences</div>
          </div>
          <div className="flex flex-row items-start justify-start">
            <ArrowBottom className={`relative transition-transform duration-300 ease-out ${isOpen ? 'rotate-[90deg]' : ''}`} />
          </div>
        </div>
        {isOpen && (
          <>
            <div className="self-stretch flex flex-col items-start justify-center font-button-small">
              <div className="self-stretch h-[125px] flex flex-row items-start justify-start gap-[12px]">
                {pairedLinks.map((pair, rowIndex) => (
                  <div key={rowIndex} className="self-stretch flex-1 flex flex-col items-start justify-start gap-[12px]">
                    {pair.map((link, linkIndex) => (
                      <Link key={linkIndex} to={link.path} className="self-stretch flex-1 rounded-roundings-xs bg-gray-6 hover:bg-blue-3 overflow-hidden flex flex-row items-center justify-between py-1 pr-2.5 pl-5 text-black-1 hover:text-black-1">
                        <div className="relative leading-[20px] font-medium">{link.label}</div>
                        <div className="flex flex-row items-start justify-start">
                          <ArrowBottom className="relative rotate-[-90deg]" />
                        </div>
                      </Link>
                    ))}
                  </div>
                ))}
              </div>
            </div>
            <FenceCalculatorCardTablet />
          </>
        )}
      </div>
      {LINKS.map((link, index) => (
        <Link key={index} to={link.path} className="w-[375px] h-12 flex flex-col items-start justify-center py-0 px-5 box-border font-medium text-black-1 hover:text-black-1">
          <div className="self-stretch rounded-roundings-xs h-9 flex flex-row items-center justify-between">
            <div className="flex flex-row items-center justify-start">
              <div className="relative leading-[24px]">{link.label}</div>
            </div>
            <div className="w-6 h-6" />
          </div>
        </Link>
      ))}
    </div>
  );
};

export default DropdownTablet;
