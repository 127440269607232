export const DEFAULT_SOURCE_VALUE = 'website';

export const ERGEON1_PHONE = '+18883743661';

// LS CONSTANTS
export const LS_ERGEON_LOCATION_DATA = 'LS_ERGEON_LOCATION_DATA';
export const LS_ERGEON_CART_DATA = 'LS_ERGEON_CART_DATA';

export const ERGEON_LICENSE_NUMBER = '#1040925';
export const ERGEON_GEORGIA_LICENSE_NUMBER = '#RBQA007338';

export const FENCE_QUANTITY_UNIT = 'ft.';
export const DRIVEWAY_QUANTITY_UNIT = 'sq.ft.';

export const INSTALLER_PREVIEW_SLUG = 'installer';
export const DIRECT_PREVIEW_SLUG = 'direct';

export const CALC_AREA_TYPE = 'Area';
export const CALC_GATE_TYPE = 'Gate';
export const CALC_SIDE_TYPE = 'Side';

export const MAP_LABEL_TYPE = 'Label';
export const MAP_LABEL_YELLOW_TYPE = 'LabelYellow';
export const MAP_CIRCLE_TYPE = 'Circle';

export const CALC_TYPE_TO_MAP_TYPE = {
  [CALC_AREA_TYPE]: MAP_LABEL_YELLOW_TYPE,
  [CALC_GATE_TYPE]: MAP_CIRCLE_TYPE,
  [CALC_SIDE_TYPE]: MAP_LABEL_TYPE,
};

export const QUOTE_STATUS_CODE_CANCELLED = 'cancelled';
export const QUOTE_STATUS_CODE_SENT = 'snt';
export const QUOTE_STATUS_CODE_APPROVED = 'app';

export const HTTP_STATUS_NOT_FOUND = 404;
export const NOT_FOUND_PAGE_PATH = '/404';

export const CONTACT_EMAIL = 'contact@ergeon.com';
export const CARD_TRANSACTION_FEE = '2%';
export const HELP_ROOT_NODE = '201900001';
export const HELP_DOMAIN_ID = '1';

export const TIMEZONE = 'America/Los_Angeles';

export const STAFF_MAP_GID = '8sKm037W1MZ8Xw_S';
export const LOCATIONS_MAP_GID = '9G6yK68VZCj9Ij4s';

export const CITIES_PAGE_PATH = '/fences/cities';
export const FENCES_PAGE_PATH = '/fences/';

export const LEAD_FULL_NAME_MAX_LENGTH = 120;

export const GRASS_PRODUCT = 'landscaping-design';

export const DECKS_PRODUCT = 'deck-installation';

export const STATEMENT_SLUG = 'statement';

export const DEFAULT_ADDRESS = '2740 Fulton Ave #451, Sacramento, CA 95821, USA';

export const FENCE_SLUG = 'fence-replacement';
export const HELP_PATH = 'help';
export const LOGOUT_PATH = 'logout';

export const LICENSES = [
  {
    // CA_LICENSE_URL
    label: 'California',
    name: '1040925, C13-Fencing, C27-Landscaping, \n C8-Concrete',
    url: 'https://www2.cslb.ca.gov/OnlineServices/CheckLicenseII/LicenseDetail.aspx?LicNum=1040925',
  },
  {
    // GA_LICENSE_URL
    label: 'Georgia',
    name: 'RBQA007338',
    url: 'https://verify.sos.ga.gov/verification/',
  },
  {
    // MD_LICENSE_URL
    label: 'Maryland',
    name: '05-150463',
    url: 'https://www.dllr.state.md.us/cgi-bin/ElectronicLicensing/OP_Search/OP_search.cgi?calling_app=HIC::HIC_registration_num',
  },
  {
    // VA_LICENSE_URL
    label: 'Virginia',
    name: '2705184433',
    url: 'https://www.dpor.virginia.gov/LicenseLookup',
  },
  {
    // PA_LICENSE_URL
    label: 'Pennsylvania',
    name: 'PA173665',
    url: 'https://hicsearch.attorneygeneral.gov/',
  },
  {
    // DC_LICENSE_URL
    label: 'Washington DC',
    name: '420223000015',
    url: '',
  },
  {
    // WASHINGTON_STATE_LICENSE_URL
    label: 'Washington State',
    name: 'ERGEOI*784QQ',
    url: 'https://secure.lni.wa.gov/verify/',
  },
  {
    // NEW_JERSEY_LICENSE_URL
    label: 'New Jersey',
    name: '13VH12704300',
    url: 'https://newjersey.mylicense.com/verification/',
  },
  {
    // FL_LICENSE_URL
    label: 'Florida',
    name: 'CRC1334117',
    url: 'https://drive.google.com/file/d/1RodOzs5eLKtlct7q-JdzFNFLizHrcWbR/view',
  },
];

// CAD constants we should later find a way to proper sync them as it seems we are duplicating code
export const CATALOG_TYPE_FENCE = 'fence-side';
export const CATALOG_TYPE_GATE = 'fence-gate';
export const CATALOG_TYPE_VINYL_GATE = 'vinyl-fence-gate';
export const CATALOG_TYPE_RETAINING_WALL = 'retaining-wall';
export const CATALOG_TYPE_NO_TYPE = '';
export const CATALOG_ID_FENCE = '364';
export const CATALOG_ID_GATE = '211';
export const DEFAULT_ZIP = '94014';
export const SCHEMA_SEPARATOR = ',';
export const GATE_TYPE_ID = 24;
