import React, { useCallback, useMemo } from 'react';

import {
  TopPanelAppNav, TopPanelNavItemButton, TopPanelNavItemLink,
} from '@ergeon/core-components/exports/UniversalTopPanel';
import { MainMenuProps } from '@ergeon/core-components/src/components/new/components/MainMenu/MainMenu';
import { ReactComponent as IconUser } from '@ergeon/core-components/src/assets/icon-user-signin.svg';
import { MainMenuInnerButton } from '@ergeon/core-components/src/components/new/components/MainMenu/types';

import { getProfileName } from '../../utils/utils';
import { AuthUser } from '../types';
import { useIsMobileView } from '../../utils/hooks';

import useMenuItems from './hooks/useMenuItems';

interface TopPanelLoggedProps {
  user: AuthUser;
  onClickPath?: (path: string) => void;
}

interface NavButton extends MainMenuInnerButton {
  onClick?: () => void;
};

const TopPanelLogged = ({ user, onClickPath }: TopPanelLoggedProps) => {
  const buttons = useMenuItems({ profileName: user?.full_name, onClickPath }) as NavButton[];
  const { isMobile } = useIsMobileView();
  const renderToggle = useCallback((props, _isMobile: boolean) => (
    <TopPanelNavItemButton {...props} data-testid="top-panel-toggle">
      <IconUser className="icon" />
      {getProfileName(user.full_name)}
    </TopPanelNavItemButton>
  ), [user]);

  const dropDownMenu = useMemo(() => {
    return { buttons, renderToggle } as MainMenuProps;
  }, [buttons, renderToggle]);

  const renderNavItem = useCallback((button: NavButton, index: number, prefix = 'top-panel-logged-') => {
    if (!button.onClick) {
      return null;
    }
    return (
      <TopPanelNavItemLink
        key={index}
        onClick={button.onClick}
        data-testid={`${prefix}${button.label}`}
      >
        {button.label}
      </TopPanelNavItemLink>
    );
  }, []);

  const renderMobileNavItems = useMemo(() => {
    return (
      <>
        {buttons.map((button, index) => (
          <React.Fragment key={index}>
            {renderNavItem(button, index)}
            {button.buttons &&
              button.buttons.map((subButton, subIndex) =>
                renderNavItem(subButton as NavButton, subIndex),
              )}
          </React.Fragment>
        ))}
      </>
    );
  }, [buttons]);

  return (
    <>
      {!isMobile && (
        <TopPanelAppNav {...dropDownMenu}>
          <div className="item-isHidden" />
        </TopPanelAppNav>
      )}
      {isMobile && renderMobileNavItems}
    </>
  );
};

export default TopPanelLogged;
