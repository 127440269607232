import {version as packageVersion} from '../../package.json';
import {initUTMs} from '../init';
import {localStorage, sessionStorage} from './Storage';
import TimeStamp from './TimeStamp';
import URLHelper from './URLHelper';
import {isHeadlessBrowser} from './utils';
import {getBrowserParams, getDocumentParams, getUTMs, UTMs} from './UTMParser';
import VisitorID from './VisitorID';
import ReferralCode from './ReferralCode';
import { 
  VISITOR_ID_KEY, SESSION_INITIAL_KEY, REFERRAL_KEY, REFERRAL_CODE_KEY, PARAMS_TIME_STAMP_KEY, USER_IP_KEY
} from '../constants';
import { IPAddressLookupReturnType } from './IPAddressLookup';

export async function getCurrentData(): Promise<UTMs> {
  return {
    'utm': getUTMs(),
    'document': getDocumentParams(),
    'browser': getBrowserParams(),
    [REFERRAL_KEY]: ReferralCode.getReferralFromUrl(),
    [PARAMS_TIME_STAMP_KEY]: TimeStamp.get(),
  };
}

export async function getSessionData({includeInitial} = {includeInitial: false}): Promise<UTMs> {
  const currentData = await getCurrentData();
  if (isHeadlessBrowser()) {
    // Always resolve `currentData` in headless Chrome
    return Promise.resolve(currentData);
  }
  // Ensure UTMs initialized
  await initUTMs();
  const baseKeys = Object.keys(currentData);
  const keys = includeInitial ? [...baseKeys, SESSION_INITIAL_KEY] : baseKeys;
  return sessionStorage.getData(keys);
}

export async function getInitialData(): Promise<UTMs> {
  const currentData = await getCurrentData();
  if (isHeadlessBrowser()) {
    // Always resolve `currentData` in headless Chrome
    return Promise.resolve({...currentData, [VISITOR_ID_KEY]: VisitorID.generate()});
  }
  // Ensure UTMs initialized
  await initUTMs();
  const keys = [...Object.keys(currentData), VISITOR_ID_KEY];
  return localStorage.getData(keys);
}

/**
 * Combines initial and session data, for internal usage only
 * @returns async UTMs
 */
export async function getSharedData(): Promise<UTMs> {
  const sessionData = await getSessionData({includeInitial: true});
  const initialData = await getInitialData();
  return {
    initial: {...initialData, packageVersion},
    session: sessionData,
  };
}

export async function getBaseEventData(): Promise<UTMs> {
  const currentData = await getCurrentData();
  const sessionData = await getSessionData();
  const initialData = await getInitialData();
  return {
    'object': {
      'initial_params': {
        'initial_referrer': initialData.document['referrer'],
        'initial_landing_page': initialData.document['href'],
        'pathname': URLHelper.getPathName(initialData.document['href']),
        'search': URLHelper.getSearch(initialData.document['href']),
        'arrival_time': TimeStamp.format(initialData[PARAMS_TIME_STAMP_KEY]),
        ...initialData['utm'],
        ...initialData['browser'],
      },
      'document_referrer': currentData.document['referrer'],
      'href': currentData.document['href'],
      'pathname': URLHelper.getPathName(currentData.document['href'] as string),
      'search': URLHelper.getSearch(currentData.document['href'] as string),
      'initial_referrer': sessionData.document['referrer'],
      'initial_landing_page': sessionData.document['href'],
      'arrival_time': TimeStamp.format(sessionData[PARAMS_TIME_STAMP_KEY]),
      ...sessionData['utm'],
      ...sessionData['browser'],
    },
    'generation_time': TimeStamp.format(currentData[PARAMS_TIME_STAMP_KEY]),
    'uuid': initialData[VISITOR_ID_KEY],
    'referral_code': await ReferralCode.get()
  };
}

export const getIpInfoFromStorage = async (): Promise<IPAddressLookupReturnType | null> => {
  try {
    let userIpInfo = null;
    const sessionData = await sessionStorage.getData([USER_IP_KEY]);
    userIpInfo = sessionData[USER_IP_KEY];
    
    if (!userIpInfo) {
      const localData = await localStorage.getData([USER_IP_KEY]);
      userIpInfo = localData[USER_IP_KEY];
    }
    return userIpInfo;
  } catch (error) {
    console.error(error);
    return null;
  }
};
