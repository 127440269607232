import React from 'react';

import { Link } from 'gatsby';

import { ReactComponent as Logo } from '../../assets/top-panel-logo.svg';
import { ReactComponent as IconPhone } from '../../assets/icon-phone-orange.svg';
import { CLICK_TO_CALL } from '../../utils/events';

import DropdownDesktop from './Dropdown/DropdownDesktop';
import CTAButton from './CTAButton';
import UserLink from './UserLink';
import { MenuProps } from './types';

interface TopPanelDesktopViewProps extends MenuProps { };

const TopPanelDesktopView = (props: TopPanelDesktopViewProps) => {
  const {
    ergeonUrl,
    phoneNumber,
    phoneLink,
    onClickCTA,
    onClickPath,
    loading,
    user,
    signInPath,
  } = props;
  return (
    <div className="w-full relative bg-base-white-100 shadow-[0px_3px_70px_rgba(0,_86,_165,_0.1)] h-16 overflow-visible flex flex-col items-center justify-center text-left text-sm text-black-1 font-button-medium">
      <div className="w-full max-w-[1180px] mx-auto flex flex-row items-center justify-between">
        <Link to={ergeonUrl as string} className="w-36 relative h-8 object-cover ml-5">
          <Logo />
        </Link>
        <DropdownDesktop />
        <div className="flex flex-row items-center justify-start">
          <div className="flex flex-row items-start justify-start gap-[0px_20px]">
            <a data-tracking-id={CLICK_TO_CALL} href={`tel:${phoneLink}`} className="rounded-roundings-xs h-9 flex flex-row items-center justify-center py-2 px-4 box-border gap-[0px_6px] text-black-1">
              <IconPhone className="w-[17.52px] relative h-[17.52px]" />
              <div className="relative leading-[20px] font-semibold">{phoneNumber}</div>
            </a>
            <CTAButton loading={loading} onClickCTA={onClickCTA} />
            <UserLink user={user} signInPath={signInPath} onClickPath={onClickPath} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopPanelDesktopView;
